import React from 'react'

import './styles/Reconocimientos.scss';

import ReconocimientosImg from '../img/ReconocimientosImg.png';
import escuadra from '../img/escuadra.png';
import metodologia from '../img/metodo.png';
import areas from '../img/areas.png';
import cumplimiento from '../img/cumplimiento.png';
import mauricio from '../img/MauricioESG.png';
import salvador from '../img/SalvadorESG.png';
import jorge from '../img/JorgeESG.png';
import alejandro from '../img/AlejandroESG.png';
import fabiola from '../img/FabiolaESG.png';
import laura from '../img/LauraESG.png';
import logo1 from '../img/logo1.png'
import logo2 from '../img/logo2.png'
import logo3 from '../img/logo3.png'










class Reconocimientos extends React.Component {
  render() {
    // console.log(this.props.data.title)
    return (
      
      <div className='mainESG'>
          
         <div class="containerESG">
        {/* <!-- Primera Sección --> */}
        
        <h1>{this.props.data.title}</h1>
        <ul>
            <li>{this.props.data.details1}</li>
            <li>{this.props.data.details2}</li>
            <li>{this.props.data.details3}</li>
            <li>{this.props.data.details4}</li>
        </ul>
    </div>

    <div class="containerESG">
        {/* <!-- Tercera Sección: Racional Estratégico --> */}
        <h2 className='h2ESG'>{this.props.data.title0}</h2>
        
        <div class="images-container">
        <img src={logo1} />
        <img src={logo2} />
        <img src={logo3} />
        </div>
        
            <ul>
            <li>{this.props.data.detailsT0a}</li>
                <li>{this.props.data.detailsT0b}</li>
                <li>{this.props.data.detailsT0c}</li>
                <li>{this.props.data.detailsT0d}</li>
                <li>{this.props.data.detailsT0e}</li>
                <li>{this.props.data.detailsT0f}</li>
                
            </ul>
        
        
        
    </div>





    <div class="containerESG">
        {/* <!-- Segunda Sección: Equipo --> */}
        <h2 className='h2ESG'>{this.props.data.title2}</h2>
        <div class="team">
            {/* <!-- Miembros del equipo --> */}
            <div class="team-member">
                <img src={mauricio}/>
                <div class="team-info">
                    <div class="name">{this.props.data.member1}</div>
                    <div class="title">{this.props.data.member1Title}</div>
                    <div class="bio">
                    {this.props.data.member1Desc}
                    </div>
                </div>
            </div>
            <div class="team-member">
                <img src={salvador}/>
                <div class="team-info">
                    <div class="name">{this.props.data.member2}</div>
                    <div class="title">{this.props.data.member2Title}</div>
                    <div class="bio">
                    {this.props.data.member2Desc}
                    </div>
                </div>
            </div>
            <div class="team-member">
                {/* <img src="ruta-a-imagen3.jpg" alt="Jorge Fernández"> */}
                <img src={jorge}/>

                <div class="team-info">
                    <div class="name">{this.props.data.member3}</div>
                    <div class="title">{this.props.data.member3Title}</div>
                    <div class="bio">
                    {this.props.data.member3Desc}
                    </div>
                </div>
            </div>
            <div class="team-member">
                {/* <img src="ruta-a-imagen4.jpg" alt="Alejandro H. Garza"> */}
                <img src={alejandro}/>

                <div class="team-info">
                    <div class="name">{this.props.data.member4}</div>
                    <div class="title">{this.props.data.member4Title}</div>
                    <div class="bio">
                    {this.props.data.member4Desc}
                    </div>
                </div>
            </div>
            <div class="team-member">
                {/* <img src="ruta-a-imagen5.jpg" alt="Fabiola Pastran"> */}
                <img src={fabiola}/>

                <div class="team-info">
                    <div class="name">{this.props.data.member5}</div>
                    <div class="title">{this.props.data.member5Title}</div>
                    <div class="bio">
                    {this.props.data.member5Desc}
                    </div>
                </div>
            </div>
            <div class="team-member">
                {/* <img src="ruta-a-imagen6.jpg" alt="Laura Martinez"> */}
                <img src={laura}/>

                <div class="team-info">
                    <div class="name">{this.props.data.member6}</div>
                    <div class="title">{this.props.data.member6Title}</div>
                    <div class="bio">
                    {this.props.data.member6Desc}
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <div class="containerESG">
        <!-- Tercera Sección: Racional Estratégico -->
        <h2 className='h2ESG'>{this.props.data.title0}</h2>
        
  
            <ul>
            <li>{this.props.data.detailsT0a}</li>
                <li>{this.props.data.detailsT0b}</li>
                <li>{this.props.data.detailsT0c}</li>
                <li>{this.props.data.detailsT0d}</li>
                <li>{this.props.data.detailsT0e}</li>
                <li>{this.props.data.detailsT0f}</li>



                
            </ul>
        
        
    </div> */}


    {/* <div class="containerESG">
        <!-- Tercera Sección: Racional Estratégico -->
        <h2 className='h2ESG'>{this.props.data.title3}</h2>
        <ul>
            <li>{this.props.data.detailsT3a}</li>
            <ul>
                <li><span class="highlight-bullet">{this.props.data.detailsT3b1}</span> {this.props.data.detailsT3b}</li>
                <li><span class="highlight-bullet">{this.props.data.detailsT3c1}</span> {this.props.data.detailsT3c}</li>
                <li><span class="highlight-bullet">{this.props.data.detailsT3d1}</span> {this.props.data.detailsT3d}</li>
            </ul>
            <li>{this.props.data.detailsT3e}</li>
            <li>{this.props.data.detailsT3f}<span class="highlight">{this.props.data.detailsT3g}</span> {this.props.data.detailsT3h}</li>
            <ul className='list-container'>
                <li className='list-item'>{this.props.data.detailsT3i}</li>
                <li className='list-item'>{this.props.data.detailsT3j}</li>
                <li className='list-item'>{this.props.data.detailsT3k}</li>
                <li className='list-item'>{this.props.data.detailsT3l}</li>
                <li className='list-item'>{this.props.data.detailsT3m}</li>
                <li className='list-item'>{this.props.data.detailsT3n}</li>
                <li className='list-item'>{this.props.data.detailsT3o}</li>
                <li className='list-item'>{this.props.data.detailsT3p}</li>
                <li className='list-item'>{this.props.data.detailsT3q}</li>
            </ul>
        </ul>
    </div> */}









     {/* <div class="containerESG">
        <!-- Cuarta Sección: Ecosistema ESG -->
        <h2 className='h2ESG'>{this.props.data.title4}</h2>
        <div class="ecosystem">
                <img className='full-width-image' src={this.props.data.imagenTitle4}/>
      </div>
      </div> */}

      {/* <div class="containerESG">
        <!-- Cuarta Sección: Ecosistema ESG -->
        <h2 className='h3ESG'>{this.props.data.title5} </h2>
        <div class="ecosystem">
                <img className='full-width-image' src={this.props.data.imagenTitle5}/>
      </div>
      </div> */}

      {/* <div class="containerESG">
        <!-- Cuarta Sección: Ecosistema ESG -->
        <h2 className='h3ESG'>{this.props.data.title6} </h2>
        <div class="ecosystem">
                <img className='full-width-image' src={this.props.data.imagenTitle6}/>
      </div>
      </div> */}

      <div class="containerESG">
        {/* <!-- Cuarta Sección: Ecosistema ESG --> */}
        <h2 className='h3ESG'>{this.props.data.title7}</h2>
        <div class="ecosystem">
                <img className='full-width-image' src={this.props.data.imagenTitle7}/>
      </div>
      </div>

      {/* <div class="containerESG">
        <h2 className='h3ESG'>{this.props.data.title8}</h2>
        <div class="ecosystem">
                <img className='full-width-image' src={this.props.data.imagenTitle8}/>
      </div>
      </div> */}

      {/* <div class="containerESG">
        <h2 className='h3ESG'>{this.props.data.title9}</h2>
        <div class="ecosystem">
                <img className='full-width-image' src={this.props.data.imagenTitle9}/>
      </div>
      </div> */}

      {/* <div class="containerESG">
        <h2 className='h3ESG'>{this.props.data.title10}</h2>
        <div class="ecosystem">
                <img className='full-width-image' src={this.props.data.imagenTitle10}/>
      </div>
      </div> */}

      {/* <div class="containerESG">
        <h2 className='h3ESG'>{this.props.data.title11}</h2>
        
        <div class="two-columns">
            <div class="column">
       
                <p>{this.props.data.textTitle11}</p>
                <p>{this.props.data.textTitle12}</p>
            </div>
            <div class="column">
               
                <p>{this.props.data.textTitle13}</p>
                <p>{this.props.data.textTitle14}</p>
                <p>{this.props.data.textTitle15}</p>

            </div>
        </div>
    
      </div> */}


      </div>
        
    )
  }
}

export default Reconocimientos;