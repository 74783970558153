import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Layout from './Layout';
import Home from '../pages/Home';
import Socios from './Socios';
import Socios2 from './Socios2';
import AreasPractica from '../pages/AreasPractica';
import EquipoLegal from '../pages/EquipoLegal';
import EquipoLegal2 from '../pages/EquipoLegal2';
import Clientes from '../pages/Clientes';
import Alianzas from '../pages/Alianzas';
import Reconocimientos from '../pages/Reconocimientos';
import Publicaciones from '../pages/Publicaciones';

import fotoEquipoLegal from '../img/leftEquipoLegal.png';
import fotoEquipoLegal2 from '../img/rightEquipoLegal2.png';
import MauricioCamposRedondo from '../img/MauricioCamposRedondo.png';
import AnaGabrielaRedonda from '../img/AnaGabrielaRedonda.png';
import EduardoPulidoRedondo from '../img/EduardoPulidoRedondo.png';
import ErikaRedondo from '../img/ErikaRedondo.png';
import YasiriSalazar from '../img/Yasiri-Salazar-C.png'
import JhonatanRedondo from '../img/JhonatanRedondo.png';
import JosueBalderasRedondo from '../img/JosueBalderasRedondo.png';
import JosePabloArroyo from '../img/Jose-Pablo-Arroyo-C.png'
import GerardoJavierSalomon from '../img/Gerardo-Salomon-C.png'
import MariaJuliaRedondo from '../img/MariaJuliaRedondo.png';
import JorgeAriasRedondo from '../img/JorgeAriasRedondo.png';
import JesusMendozaRedondo from '../img/JesusMendozaRedondo.png';
import DiegoMaxRedondo from '../img/DiegoMaxRedondo.png';
import EduardoLuz from '../img/Eduardo-Luz-C.png'
import triangulo from '../img/triangulo.png';
import MauricioCamposCuadrado from '../img/MauricioCampos.png';
import JorgeAriasCuadrado from '../img/JorgeArias.png';
import JesusMendozaCuadrado from '../img/JesusMendoza.png';
import DiegoMaxCuadrado from '../img/DiegoMax.png';
import Maria from '../img/Maria.png';
import Eduardo from '../img/Eduardo.png';
import Jonathan from '../img/Jonathan.png';
import Josue from '../img/Josue.png';
import Ana from '../img/Ana.png';
import Erika from '../img/Erika.png';
import Yasiri from '../img/Yasiri-Salazar.png'
import EduardoL from '../img/Eduardo-Luz.png'
import Gerardo from '../img/Gerardo-Salomon.png'
import JosePablo from '../img/Jose-Pablo-Arroyo.png'
import Adrian from '../img/Adrian.png'
import AdrianC from '../img/Adrian-C.png'
import Jaqueline from '../img/Jaqueline.png'
import JaquelineC from '../img/Jaqueline-C.png'

import metodologia from '../img/metodo.png';
import metodologiaIng from '../img/metodoing.png';

import areas from '../img/areas.png';
import cumplimiento from '../img/cumplimiento.png';
import agencias from '../img/agencias.png';
import organizacion from '../img/organizacion.png';
import matriz from '../img/matriz.png';
import impacto from '../img/impacto.png';







class App extends React.Component {

  state =
    {
      actual: 'es',
      language: {
        es:
        {
          reconocimientos: {
            title: 'Antecedentes y Concepto ESG',
            subtitle: '',
            details1: 'El concepto de ESG fue lanzado en enero de 2004 por el entonces SG de la ONU, Kofi Annan (Corporación Financiera Internacional (IFC)), dirigiéndose a los 50 directores ejecutivos de las instituciones financieras más grandes del mundo para integrar los estándares de cumplimiento en su práctica como inversores institucionales, hoy es sinónimo de los más altos estándares de gobierno corporativo a nivel global y se ha convertido en una clasificación de empresas que engloba más de $20 trillones a nivel mundial',
            details2: 'En la actualidad, la mayoría de los grandes inversores institucionales globales (BlackRock, Robeco SAM, IFC, entre otros) alientan de forma proactiva a las empresas en las que invierten, cotizadas o privadas, a adherirse a los estándares de cumplimiento de ESG. ',
         //   details3: 'Somos pioneros en el desarrollo de un Sistema Integral de Cumplimiento ESG y hemos desarrollado una metodología robusta y sistemática para el despliegue y adopción del SIC ESG y estándares de informes para nuestros clientes.',
           // details4: 'Nuestros clientes se benefician de nuestra experiencia para mejorar significativamente su cultura de cumplimiento dentro de un marco sistemático que posee métricas claras e hitos concretos, los cuales son esenciales para la búsqueda de ventajas competitivas duraderas y un crecimiento sostenible.',
           title2: "",
           
           
            title2: 'Nuestro Equipo de Expertos ESG',
            member1: 'Mauricio Ibáñez',
            member1Title: 'Socio Director',
            member1Desc: 'Socio fundador de IHMA - Ibáñez Hoffmann Mendoza Andrade, firma legal especializada en gobierno y derecho corporativo; Ex Secretario Consejo Grupo México S.A.B. y miembro consejero de diversas corporaciones. Licenciado en derecho por la Universidad Iberoamericana con Master en derecho en el ámbito bancario, financiero y corporativo, por Columbia University - School of Law.',
            member2: 'Salvador Nava',
            member2Title: 'Socio Director',
            member2Desc: 'Socio fundador DPTA; graduado en derecho por la Universidad Iberoamericana; Doctor en derecho por la Universidad Complutense de Madrid, especialista en derecho constitucional y ciencias políticas por el Centro de Estudios constitucionales de Madrid. Catedrático y miembro de Conacyt así como de la comisión técnica del Grupo Oaxaca a cargo de la redacción de la primera versión de la Ley Federal de Transparencia y Acceso Público a la Información de Gobierno.',
            member3: 'Jorge Fernández',
            member3Title: 'Socio Director',
            member3Desc: 'Abogado especialista en Cumplimiento ESG, actualmente of-counsel de la firma Arent Fox LLP basada en Washington D.C., y Socio Director de la firma consultora especialista en Compliance-ESG JAFD Consulting LLP, previamente Director General Jurídico para Mars Inc., y previamente para Motorola Latino América. Abogado y Doctor en derecho por la Escuela Libre de Derecho.',
            member4: 'Alejandro H. Garza',
            member4Title: 'Socio Director',
            member4Desc: 'Alejandro aporta más de 25 años de experiencia en gestión de inversiones y banca de inversión, y se especializa en estrategias de capital global sustentables/ESG a largo plazo. Anteriormente (2007-2016) PM y socio director de Ashmore Equities US, con más de $11 billones en activos bajo administración. MBA y BSc Tecnológico de Monterrey.',
            member5: 'Fabiola Pastran',
            member5Title: 'Asociada',
            member5Desc: 'Analista Ninter Group USA (Miami, Florida); Analista Guangzhou Juvan LLC, China y Venezuela. BSc., MBA University of Miami, CFA ESG Investing.',
            member6: 'Laura Martinez',
            member6Title: 'Socia',
            member6Desc: 'Laura colaboró durante casi 15 años con la calificadora de riesgo S&P Global Ratings en México, participando como directora y analista líder en el grupo de calificaciones corporativas. Profesor asociado ITESM Campus Querétaro desde 2020. BSc Tecnológico de Monterrey. Candidato CFA Nivel 3.',
            title0:'Firmas Boutique Especializadas en la Implementación del Sistema Integral de Cumplimiento ESG',
            
            detailsT0a: 'El Consorcio se integra de una serie de firmas boutique que combinan la experiencia profesional de sus directores, asociados y junta de asesores en el despliegue del Sistema Integral de Cumplimiento (“SIC”) - ESG para corporaciones cotizadas y privadas.',
            detailsT0b: 'Nuestro conjunto de servicios altamente especializado incluye asuntos regulatorios, de derecho corporativo y de valores, sistemas de gestión de riesgos ambientales, estándares de gobernanza y responsabilidad social, respaldados por una experiencia práctica sustancial en derecho corporativo, gestión de inversiones, operaciones industriales y comunicaciones corporativas.',
            detailsT0c: 'Somos pioneros en el desarrollo de un Sistema Integral de Cumplimiento ESG y hemos desarrollado una metodología robusta y sistemática para el despliegue y adopción del SIC ESG y estándares de informes para nuestros clientes.',
            detailsT0d: 'Nuestros clientes se benefician de nuestra experiencia para mejorar significativamente su cultura de cumplimiento dentro de un marco sistemático que posee métricas claras e hitos concretos, los cuales son esenciales para la búsqueda de ventajas competitivas duraderas y un crecimiento sostenible.',
            
            
            
            
           // detailsT0a: 'El concepto de ESG fue lanzado en enero de 2004 por el entonces SG de la ONU, Kofi Annan (Corporación Financiera Internacional (IFC)), dirigiéndose a los 50 directores ejecutivos de las instituciones financieras más grandes del mundo para integrar los estándares de cumplimiento en su práctica como inversores institucionales.            ',
           // detailsT0b: 'En la actualidad, la mayoría de los grandes inversores institucionales globales (BlackRock, Robeco SAM, IFC, entre otros) alientan de forma proactiva a las empresas en las que invierten, cotizadas o privadas, a adherirse a los estándares de cumplimiento de ESG. Por esta razón, el marco ESG se ha convertido en sinónimo del más alto estándar de cumplimiento y gobierno corporativo a nivel mundial.            ',
          //  detailsT0c: 'En México, el 22 de junio de 2020, la BMV en asociación con “S&P Dow Jones Indices” lanzó su índice ESG para promover estándares de buen gobierno corporativo y sustentabilidad.             ',
          //  detailsT0d: 'A la fecha, existen varios índices y agencias calificadoras (S&P, MSCI, ISS, EIKON, entre otras) que evalúan e informan el desempeño ESG de las corporaciones. Algunos elementos ESG han ganado tanta importancia, que se están convirtiendo en parte de las regulaciones de los mercados de valores en varias jurisdicciones, incluida la Unión Europea.            ',
           // detailsT0e: 'GRI (Global Reporting Initiative) es una institución global independiente con sede en los Países Bajos, fundada en Boston en 1997 en respuesta al desastre del Exxon Valdez, que proporciona informes gratuitos  sobre los estándares de sustentabilidad para las empresas.            ',
           // detailsT0f: 'La combinación del marco ESG con los estándares de informes GRI, proporciona a las corporaciones y a sus diferentes partes interesadas un lenguaje común de cumplimiento y constituye un poderoso conjunto de herramientas para la sustentabilidad y la creación de valor.            ',
            // title3: 'Racional Estratégico del Sistema Integral de Cumplimiento ESG',
            // detailsT3a: 'Hoy las empresas reconocen la importancia estratégica de alcanzar y mantener los más altos estándares de Cumplimiento Corporativo con los objetivos de:',     
            // detailsT3b: 'contingencias y mitigar riesgos tangibles e intangibles.',
            // detailsT3b1: '• Prevenir ',
            // detailsT3c: 'a la empresa, sus activos, a sus accionistas, sus ejecutivos y empleados, así como su entorno.',
            // detailsT3c1: '• Proteger ',
            // detailsT3d: 'a la empresa de la manera más favorable ante reguladores y diferentes “stakeholders”.',
            // detailsT3d1: '• Posicionar ',
            // detailsT3e: 'Tiene un impacto tangible en el costo de capital de las corporaciones, contribuye a atraer y retener talento que a su vez contribuye a la creación de valor económico.',
            // detailsT3f: 'El ',
            // detailsT3g: 'Sistema Integral de Cumplimiento ESG ',
            // detailsT3h: 'abarca de forma global muchos de los conceptos que forman parte las regulaciones en diversas jurisdicciones, por lo cual prepara de forma robusta a la empresa y sus directivos y consejeros ante contingencias legales muy serias:',
            // detailsT3i: '• Leyes y regulaciones bancaria',
            // detailsT3j: '• Foreign Corrupt Practices Act (FCPA) de EUA',
            // detailsT3k: '• UK Antibribery Act',
            // detailsT3l: '• OCDE Reporting Standards',
            // detailsT3m: '• Ley Anticorrupción Brasil',
            // detailsT3n: '• Sistema Nacional Anticorrupción México',
            // detailsT3o: '• LILCCPD de España',
            // detailsT3p: '• Tratado ONU',
            // detailsT3q: '• Aspectos del USMCA (Anteriormente TLCAN)',
            // detailsT3r: '',
            // title4: 'El Ecosistema ESG en Funcionamiento',
            // imagenTitle4: `${agencias}`,
            // title5: 'Diseño de un Sistema Integral de Cumplimiento ',
            // imagenTitle5: `${cumplimiento}`,
            // title6: 'Marco de Monitoreo y  Medición del Sistema Integral de Cumplimiento',
            // imagenTitle6: `${areas}`,
            title7: 'Nuestra Metodología',
            imagenTitle7: `${metodologia}`,
            // title8: 'Organización “Tripartita”            ',
            // imagenTitle8: `${organizacion}`,
            // title9: 'Nuestra “Matriz Semáforo ESG"',
            // imagenTitle9: `${matriz}`,
            // title10: 'Proceso de Revisión - Creación - Adopción - Divulgación',
            // imagenTitle10: `${impacto}`,
            // title11: 'BlackRock: Carta de Larry Fink a los Directores Ejecutivos 2022            ',
            // textTitle11: 'En 2018, exhorté a todas las empresas a expresar su propósito y la manera en que este beneficia a todos los stakeholders, como serían accionistas, empleados, clientes y las comunidades en las que operan. A lo largo de 2020 vimos cómo las compañías con propósito y mejores perfiles ambientales, sociales y de gobierno corporativo (ESG) lograron un mejor desempeño que sus pares. Durante 2020, el 81% de índices sostenibles, de una selección mundialmente representativa, tuvo mejor desempeño que los índices de referencia. Este mejor desempeño fue aún más marcado durante la desaceleración en el primer trimestre, otro ejemplo de la resiliencia de los fondos sostenibles que también hemos observado en desaceleraciones anteriores. Y la amplia gama de opciones de inversión sostenibles continuará despertando el interés de los inversionistas por estos fondos, como lo vimos en 2020.',
            // textTitle12: 'Desde enero a noviembre del 2020, los inversionistas en fondos de inversión y ETFs invirtieron USD 288.000 millones globalmente en activos sostenibles, un incremento del 96% con respecto a todo el 2019¹. Creo que esto representa el comienzo de una transición larga pero rápidamente acelerada – una que ocurrirá durante muchos años y transformará los precios de todo tipo de activos. Sabemos que el riesgo climático es un riesgo de inversión. Pero también creemos que la transición climática presenta una oportunidad de inversión histórica.',
            // textTitle13: 'El mundo avanza hacia las cero emisiones netas y BlackRock considera que la mejor forma de apoyar a nuestros clientes es estando a la vanguardia de esa transición. Hoy en día somos carbono-neutral en nuestras propias operaciones y estamos comprometidos con apoyar el objetivo de cero emisiones netas de gases de efecto invernadero para 2050 o antes. Ninguna compañía puede planear más de treinta años, pero creemos que todas las compañías - incluyendo BlackRock - deben empezar a abordar la transición hacia las cero emisiones netas. En este sentido, estamos dando varios pasos para ayudar a los inversionistas a preparar sus portafolios para un mundo de cero emisiones netas, entre ellos la captura de oportunidades creadas por la transición hacia este objetivo.',
            // textTitle14: 'Pero los resultados van más allá. No solo los índices ESG de mercado general exhiben un mejor desempeño que sus contrapartes, sino que, dentro de los sectores, desde el automovilístico y el bancario hasta el de petróleo y gas, notamos otra divergencia: las compañías con mejores perfiles ESG tienen mejor desempeño que sus pares, pues gozan de una “prima de sostenibilidad”⁵.',
            // textTitle15: 'Aunque es inevitable que la transición sea compleja y difícil, es esencial forjar una economía más resiliente que beneficie a más personas. Tengo gran optimismo con respecto al futuro del capitalismo y la salud de la economía, no a pesar de la transición energética, sino gracias a ella.',

          
          },
          alianzas: {
            title: 'Alianzas Estratégicas',
            subtitle: 'Capacidades internacionales de IHMA',
            details1: 'Hoy vivimos en un mundo enfocado en los mercados globales y las transacciones comerciales modernas que abarcan elementos de diferentes jurisdicciones nacionales y extranjeras. La capacidad de práctica internacional de IHMA ayuda a los clientes a navegar estos mercados a menudo complejos e inciertos y sus sistemas legales. Ayudamos a los clientes a promover su interés comercial internacional en México y mercados relevantes. Los abogados de IHMA han estudiado y trabajado en el extranjero, hablan idiomas extranjeros y conocen diferentes culturas empresariales. Además, la Firma cuenta con una vasta red de abogados y firmas de abogados extranjeros en todo el mundo para ayudar a ésta a brindar soluciones integrales para sus clientes que desean aprovechar estos mercados globales y promover sus intereses comerciales internacionales.',
            details2: 'IHMA y Leger Ketchum & Cohoon, PLLC (LKC), una firma de abogados líder en el área de Houston, con oficinas en The Woodlands, Boerne-San Antonio y Dallas, Texas, se han asociado como parte de una alianza estratégica para manejar el comercio internacional y asuntos fronterizos tanto en Estados Unidos como en México. Al unir fuerzas en una alianza estratégica, los recursos combinados de los abogados de IHMA admitidos para ejercer en México y los abogados de LKC admitidos para ejercer en Texas y otras jurisdicciones de EE. UU., nos permite brindar asesoría legal integral e integral en cualquier asunto corporativo, comercial, laboral o de otro tipo regido bajo el tratado comercial Estados Unidos - México - Canadá (USMCA) que reemplazó y reemplazó al NAFTA.',
            details3: 'Después de trabajar durante casi dos décadas como Asesor Jurídico de varias empresas multinacionales importantes en las industrias automotriz, farmacéutica, nutricional, energética y de servicios públicos, manejando responsabilidades corporativas en México y América Latina, Diego Andrade Max se unió a IHMA en 2017 y encabezó nuestros esfuerzos para abrir una oficina enlace en Woodlands, Texas en 2018. El propósito de esta oficina es estar más cerca de nuestros clientes con sede en EE. UU. que hacen negocios en México. Para mas información sobre los servicios de nuestra firma aliada por favor visitar',
            details4: 'El siguiente paso ha sido establecer una alianza estratégica con una firma de Texas a raíz de los cuestionamientos constantes de nuestros clientes extranjeros que hacen negocios en México sobre el acceso a los servicios legales en Texas, así como los clientes, personas físicas mexicanas de alto poder adquisitivo que han buscado empresas en Texas. Nuestro socio, Diego Andrade maneja la alianza IHMA-LKC y divide su tiempo entre Houston y Ciudad de México.',
          },
          footer: {
            terms: 'Terminos y condiciones',
            politicy: 'Politicas de privacidad ',
            contact: 'Contacto',
            join: 'Unete a nuestras',
            social: 'Redes Sociales',
          },
          home: {
            heroDescription1: 'Consultoría',
            heroDescription2: 'Legal Internacional',
            greyDecorate: 'Gracias a nuestra integración con la firma BALL PLLC, en junio de 2023, el Bufete ahora bajo el estandarte “BALL – IHMA”, cuenta con la posibilidad de extender sus servicios y capacidades en ubicaciones estratégicas en Estados Unidos, Sudamérica, Europa, Asia y Medio Oriente.',
            details1a: 'Ibañez, Hoffmann, Mendoza y Andrade, fue concebido como un Bufete que ofrece servicios integrales a clientes nacionales e internacionales acostumbrados a recibir servicios legales de la más alta calidad por parte de los más grandes y destacados despachos, pero con un nivel de atención personalizada en todos los asuntos confiados a éste, que claramente lo distingue de otras firmas legales tradicionales de mayor tamaño.',
            details1b: 'Los antecedentes académicos y profesionales de los    del Bufete, en dichas latitudes permiten a BALL - IHMA entender, las necesidades de los clientes de una mejor manera, así como los aspectos legales que inciden en una operación internacional y brindar respuestas de calidad en tiempo oportuno de acuerdo a las expectativas de los clientes más exigentes.',
            details2a: 'Perspectiva actualizada de negocios',
            details2b: 'Uno de nuestros principales objetivos consiste en participar proactivamente en los asuntos de nuestros clientes, proporcionando asesoría práctica y confiable a sus requerimientos. La calidad de nuestros servicios está a la altura de las exigencias de un mundo globalizado y en constante transformación.',
            dir1: 'Bosque de Ciruelos 130 -501',
            dir2: 'Col. Bosques de las Lomas,',
            dir3: 'Ciudad de México, 11700',
            office: 'Oficina Ciudad de México',

          },
          equipoLegal2:
          {
            title: 'Equipo Legal',
            details: 'Los abogados de IHMA son reconocidos por su innovación legal y su excelente servicio a clientes',
          },
          equipoLegal:
          {
            title: 'Acerca de los Socios',
            details: 'Los abogados de IHMA son reconocidos por su innovación legal y su excelente servicio a clientes',
          },
          nav:
          {
            link1: 'Sobre Nosotros',
            link2: 'Areas de Práctica',
            link3: 'Equipo Legal',
            link31: 'Socios',
            link32: 'Asociados',
            link4: 'Clientes',
            link5: 'Consorcio ESG',
            link6: 'Alianzas Estratégicas',
            link7: 'Nuestras Oficinas',
            actual: 'En'
          },
          dataSocios2:
            [
              {
                id: '1',
                socioTitle: null,
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'María Julia Bugnest',
                image: `${MariaJuliaRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad de Buenos Aires, (UBA), Argentina. (Licenciatura, 2008)        ',
                ],
                expProfesional: ['Microsoft, South Cone. Argentina. (2007-2009)        ',
                  'Abeledo Gottheil Abogados. Argentina. (2009-2010)',
                  'Estudio O´Farrell Abogados. Argentina. (2010-2011)                        ',
                  'Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-presente).                      ',
                ],
                areasDePractica: [
                  'Derecho Corporativo, Fusiones y Adquisiciones.',
                ],
                imagenCuadrada: `${Maria}`,
                estatusSocio: "Asociada",
                nacSocio: "Buenos Aires,Argentina. Julio 15, 1983.",
                titulacionSocio: "Admitido para ejercer, 2008",
                idiomaSocio: "Español, Inglés",
                idiomaTitle: "Idioma:",
                emailSocio: " jbugnest@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/MariaJuliaBugnest.vcf"
              },
              {
                id: '2',
                socioTitle: null,
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Eduardo Chávez Pulido',
                image: `${EduardoPulidoRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Tecnológica de México (J.D. 2002)',
                ],
                expProfesional: ['Martín del Campo y Asociados, S.C. (2002-2003)',
                  'Cardoso y Asociados, S.C. (2003-2004)',
                  'Buenfil & Hoffmann (2004-2006)',
                  'Ibáñez & Maroto, S.C., Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-presente).',
                ],
                areasDePractica: ['Consultoría y litigio laboral', ' Seguridad Social',
                ],
                imagenCuadrada: `${Eduardo}`,
                estatusSocio: "Asociado",
                nacSocio: "Ciudad de México. Enero 31, 1981.",
                titulacionSocio: "Admitido para ejercer, México, 2003",
                idiomaSocio: "Español e Inglés (Básico)",
                idiomaTitle: "Idioma:",
                emailSocio: " echavez@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/EduardoChavezPulido.vcf"
              },
              {
                id: '3',
                socioTitle: null,
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Adrián Monter Alonso',
                image: `${AdrianC}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Panamericana – estudiante (presente)      ',
                ],
                expProfesional: ['BALL-IHMA (enero 2024-presente)        ',
                ],
                areasDePractica: [
                  'Derecho corporativo',
                ],
                imagenCuadrada: `${Adrian}`,
                estatusSocio: "Asociado",
                nacSocio: "Nacido en Ciudad de México. Noviembre 9, 2003.",
                // titulacionSocio: "Admitido para ejercer, México, 2021",
                idiomaSocio: "Español ",
                emailSocio: "amontaner@ihma.law",
                idiomaTitle: "Idioma:",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/JosueHernandezBalderas.vcf"

              },
              {
                id: '4',
                educacionTitle: 'Educación',
                socioTitle: null,
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Gerardo Javier Salomón Ramón',
                image: `${GerardoJavierSalomon}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Insurgentes Plantel Tlalpan (2021).',
                ],
                expProfesional: ['Ronquillo & Hernández Abogados S.C. (2019 –2022) ',
                  'Anzures y Morales Abogados, S.C. (2022)',
                  'IHMA Abogados y Consultores, S.C. (2023), BALL-IHMA (Junio 2023-presente).',
                ],
                areasDePractica: ['Derecho laboral y Seguridad Social',
                ],
                imagenCuadrada: `${Gerardo}`,
                estatusSocio: "Asociado",
                nacSocio: "Nacido en Ciudad de México. Diciembre 22, 1999.",
                titulacionSocio: "Admitido para ejercer, 2023, México",
                idiomaSocio: "Español",
                idiomaTitle: "Idioma:",
                emailSocio: " gsalomon@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/AnaGabrielaNunezRodriguez.vcf"
              },
              {
                id: '5',
                socioTitle: null,
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Jonathan Retana Pérez',
                image: `${JhonatanRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad de las Américas - UDLA (Licenciatura, 2006)',
                ],
                expProfesional: ['Ortiz Noriega y González, S.C. (2005-2011)',
                  'Pronósticos para la Asistencia Pública (2011-2014)',
                  'Naviera Integral, S.A. de C.V. (2014-2017)                        ',
                  'Grupo Sacbes, S.C. (2017-2020)                        ',
                  'IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-presente).'
                ],
                areasDePractica: [
                  'Derecho Administrativo, Civil, Mercantil, Corporativo y Propiedad Intelectual',
                ],
                imagenCuadrada: `${Jonathan}`,
                estatusSocio: "Asociado",
                nacSocio: "Ciudad de México. Junio 24, 1983.",
                titulacionSocio: "Admitido para ejercer, México, 2010",
                idiomaSocio: "Español, Inglés y Francés(Básico) ",
                idiomaTitle: "Idioma:",
                emailSocio: " jretana@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/JonathanRetanaPerez.vcf"
              },
              {
                id: '6',
                socioTitle: 'Miembro',
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Erika Velázquez Blakely',
                image: `${ErikaRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Nacional Autónoma de México (Licenciatura 1997)',
                  'Universidad Nacional Autónoma de México / Diplomado en Derecho Corporativo (2019)',
                ],
                expProfesional: ['Bufete De Presno y Asociados, S.C. (1994-1999)        ',
                  'Buenfil & Hoffmann, S.C. (1999-2004)                            ',
                  'Ibáñez & Maroto, S.C., Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-presente).',
                ],
                socioMiembroDe: ['Barra Mexicana, Colegio de Abogados, A.C.',
                ],
                areasDePractica: ['Derecho Laboral consultoría y litigio Auditoría legal Seguridad Social, Corporativo-laboral Laboral.',],
                imagenCuadrada: `${Erika}`,
                estatusSocio: "Asociada",
                nacSocio: "Ciudad de México.Abril 4, 1974.",
                titulacionSocio: "Admitida para ejercer, México, 1998",
                idiomaSocio: "Español e Inglés",
                idiomaTitle: "Idioma:",
                emailSocio: " ehoffmann@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              },
              {
                id: '7',
                socioTitle: 'Acreditaciones',
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Yasiri Salazar Martínez ',
                image: `${YasiriSalazar}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Nacional Autónoma de México FES Cuatitlán (Licenciada en Contaduría 2006)', 
                'IEE Instituto de Especialización para Ejecutivos (Maestría en Impuestos 2018)',
                'Universidad Tecnológica de México (Licenciada en Derecho (En curso)',
                ],
                expProfesional: ['Ibáñez Maroto Schriever & Hoffmann, S.C. – Administrativa Contable (2008-2011), IHMA Abogados y Consultores, S.C. Contadora General (2011-2023), BALL-IHMA (Junio 2023-presente).      ',
                  
                ],
                socioMiembroDe: ['Asociación Mexicana de Contadores Públicos – Certificado de Contador Público',
                'Cámara Mexicana de la Industria de la Construcción – Formación Oficial en SIROC',
                ],
                areasDePractica: ['Contabilidad, Fiscal, Seguro Social, Administración de Nóminas, Aspectos Administrativos en REPSE y SIROC',],
                imagenCuadrada: `${Yasiri}`,
                estatusSocio: "Asociada",
                nacSocio: "Ciudad de México. Septiembre 1, 1982.",
                titulacionSocio: "Admitida para ejercer, México, 2006",
                idiomaSocio: "Español e Inglés (Básico)",
                idiomaTitle: "Idioma:",
                emailSocio: " ysalazar@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              },
              {
                id: '8',
                // socioTitle: 'Acreditaciones',
                educacionTitle: '',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Eduardo Luz Guerra ',
                image: `${EduardoLuz}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['',
                ],
                expProfesional: ['Ibáñez & Maroto, S.C; Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022 - Paralegal), BALL-IHMA (Junio 2023-presente - Paralegal). ',
                  
                ],
                socioMiembroDe: ['',
                ],
                areasDePractica: ['Derecho laboral y Asuntos Sindicales',],
                imagenCuadrada: `${EduardoL}`,
                estatusSocio: "Asociado",
                nacSocio: "Nacido en Michoacán, México. Diciembre 10, 1975.",
                titulacionSocio: "",
                idiomaSocio: "Español",
                idiomaTitle: "Idioma:",
                emailSocio: " eluz@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              },
              {
                id: '9',
                // socioTitle: 'Acreditaciones',
                educacionTitle: '',
                expTitle: 'Experiencia Profesional',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Jaqueline Santiago Torres',
                image: `${JaquelineC}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['',
                ],
                expProfesional: ['Barak Rabah Firma de Abogados S.C. (2020-2021); Lofton Servicios Integrales S.C (2021-2022); Adamantine (2022-2023); IHMA Abogados y Consultores, S.C. (2023); BALL-IHMA (Junio 2023-presente).',
                  
                ],
                socioMiembroDe: ['',
                ],
                areasDePractica: ['Derecho laboral y Derecho Civil',],
                imagenCuadrada: `${Jaqueline}`,
                estatusSocio: "Asociado",
                nacSocio: "Nacida en Ciudad de México, México. Abril 5, 1999.",
                titulacionSocio: "Admitida para ejercer, México 2023.",
                idiomaSocio: "Español",
                idiomaTitle: "Idioma:",
                emailSocio: " jsantiago@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              },
            ],
          dataSocios:
            [
              {
                id: '1',
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                socioTitle: 'Miembro',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Mauricio Ibáñez Campos',
                image: `${MauricioCamposRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Iberoamericana (Licenciatura, 1993)',
                  'Columbia University (Maestría en Derecho, 1999)',
                ],
                expProfesional: [
                  'Baker & McKenzie, S.C. (1990-1994)',
                  'White & Case, S.C. (1995-1997, 1999-2001)',
                  'White & Case, LLP, New York (1997-1998)',
                  'Jáuregui, Navarrete, Nader y Rojas, S.C.',
                  '(Práctica Internacional Asociada con Mayer, Brown,Rowe & Maw), (2001-2004)',
                  'Ibáñez & Maroto, S.C., Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-presente).'
                ],
                socioMiembroDe: [
                  'Colegio Mexicano de Abogados',
                  'The Americas Society',
                  ' Barra Mexicana, Colegio de Abogados, S.C.',
                  'American Bar Association',
                  'International Bar Association'
                ],
                otros: [
                  // '1',
                  // '2'
                ],
                areasDePractica: [
                  'Derecho Corporativo, Fondos Privados, Fusiones y Adquisiciones, Financiamiento de Proyectos, Reestructuras, Telecomunicaciones, Transporte, Inmobiliario, Minería, Energía, Gas y Pet.'
                ],
                imagenCuadrada: `${MauricioCamposCuadrado}`,
                estatusSocio: "Socio",
                nacSocio: "Ciudad de México. Noviembre 8, 1969.",
                titulacionSocio: "Admitido para ejercer, México, 1994",
                idiomaSocio: "Español e Inglés",
                idiomaTitle: "Idioma:",
                emailSocio: " mibanez@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/MauricioIbanezCampos.vcf"
              },
              {
                id: '2',
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                socioTitle: 'Miembro',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Jorge Hoffmann Arias',
                image: `${JorgeAriasRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Iberoamericana (Licenciatura 1991)'],
                expProfesional: [
                  'Zitro-Lex, S.A. de C.V. (1987-1989)',
                  'Alvarez, Cantón y Peters, S.C. (1989-1991)',
                  'Hoffmann y Asociados, S.C. (1991-1998)',
                  'Buenfil & Hoffmann, S.C. (1998-2005)',
                  'Ibáñez Maroto, S.C, Ibáñez Maroto Schriever & Hoffmann S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022)',
                  'BALL-IHMA (Junio 2023-presente)',
                ],
                socioMiembroDe: [
                  "Barra Mexicana, Colegio de Abogados, S.C.",
                ],
                areasDePractica: [
                  "Derecho Laboral",
                  "Seguridad Social"
                ],
                imagenCuadrada: `${JorgeAriasCuadrado}`,
                estatusSocio: "Socio",
                nacSocio: "Ciudad de México. Noviembre 7, 1967.",
                titulacionSocio: "Admitido para ejercer, México, 1995",
                idiomaSocio: "Español e Inglés",
                idiomaTitle: "Idioma:",
                emailSocio: "jhoffmann@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/JorgeHoffmannArias.vcf"
              },
              {
                id: '3',
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                socioTitle: 'Miembro',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Jesús Mendoza Henríquez',
                image: `${JesusMendozaRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: [
                  'Universidad Anáhuac (Licenciatura, 2000)',
                  'Pepperdine University (Juris Doctor – EEUU)',
                  'Strauss Institute for Dispute Resolution, Pepperdine',
                  'University (Maestría en Resolución Alternativa de Disputas Internacionales 2004)',
                ],
                expProfesional: [
                  'Buenfil & Hoffmann, S.C. (Pasante 1998-2000)',
                  'Comisión Federal de Competencia Económica (Director General Adjunto y Director General de Asuntos Jurídicos (2004-2008) )',
                  'Mediador de la Corte de Los Ángeles, California (Clínica de Resolución Alternativa de Disputas 2003)',
                  'Grupo Sacbes (Socio fundador 2008-2018)',
                  'IHMA Abogados y Consultores, S.C. (2018 – 2022)',
                  'BALL-IHMA (Junio 2023-presente)',


                ],
                socioMiembroDe: ['Barra Mexicana, Colegio de Abogados, S.C.',
                ],
                areasDePractica: ['Competencia Económica',
                  'Derecho Administrativo – Consultoría y litigio',
                  'Derecho Corporativo',
                  'Derecho de las Telecomunicaciones',
                  'Fusiones y Adquisiciones',
                  'Derecho Energético.',
                ],
                imagenCuadrada: `${JesusMendozaCuadrado}`,
                estatusSocio: "Socio",
                nacSocio: "Ciudad de México.Diciembre 10, 1975.",
                titulacionSocio: "Admitido para ejercer, México, 2001",
                idiomaSocio: "Español e Inglés",
                idiomaTitle: "Idioma:",
                emailSocio: "jmendoza@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/JesusMendozaHenriquez.vcf"
              },
              {
                id: '4',
                educacionTitle: 'Educación',
                expTitle: 'Experiencia Profesional',
                socioTitle: 'Miembro',
                areasTitle: 'Áreas de Práctica',
                nombre: 'Diego Andrade Max',
                image: `${DiegoMaxRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Iberoamericana (Licenciatura, 1992)',
                  'Universitaet Wien, Austria (Doctorado,Derecho Internacional y Derecho Comunitario,1994)',
                  'The University of Chicago Law School (LL.M, 1996)',

                ],
                expProfesional: [
                  'Von Wobeser y Sierra, S.C. (Pasante 1988–1992)',
                  'ICC International Court of Arbitration (Pasante, 1993)',
                  'Comision Federal de Competencia (Sub-Director Jurídico 1994 -1995)',
                  'Gardner Carton Douglas, Chicago (Pasante extranjero 1996-1997)',
                  'Freshfields Deringer, Bruselas UE (Asociado 1997-1999)',
                  'BOCAR, S.A. de C.V. (Sub Gerente Legal 1999-2005)',
                  'Bristol Myers Squibb de Mexico, S. de R.L. de C.V. (Director Jurídico 2005-2008)',
                  'Bristol Myers Squibb Company, Princeton NJ (Director Juródico Adjunto para Latinoamérica, Región Intercontinental, 2008-2012)',
                  'Aggreko LLC, Houston TX (Vice Presidente Riesgo Comercial y Legal América Latina 2013-2017)',
                  'IHMA Abogados y Consultores, S.C. (2017 – 2022)',
                  'BALL-IHMA (Junio 2023-presente)',
                ],
                socioMiembroDe: [
                  "Barra Mexicana, Colegio de Abogados",
                  "Consultor Foráneo, certificado por el Tribunal Supremo del Estado de Texas"
                ],
                areasDePractica: ["Corporativo, Contratos, Competencia, Licitaciones, Internacional, Arbitraje y  Comercio Exterior. Experiencia en industrias automotriz, farmacéutica, nutricionales y energía. "],
                imagenCuadrada: `${DiegoMaxCuadrado}`,
                estatusSocio: "Socio",
                nacSocio: "Ciudad de Nueva York. Enero 5, 1967.",
                titulacionSocio: "Admitido para ejercer, México, 1992.",
                idiomaSocio: "Español, Inglés, Alemán y Francés",
                idiomaTitle: "Idioma:",
                emailSocio: " dandrade@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/DiegoAndradeMax.vcf"
              },
            ],
          socioSolicitado: "",
          AreasPractica: {
            id: "1",
            enfoque: "Nuestro Enfoque:",
            title: "Areas de Práctica",
            subtitle:
              "Altos estándares de calidad y una amplia gama de servicios",
            description:
              "Cada uno de los socios, asociados y pasantes del Bufete se Especializan en una o más de las siguientes áreas de práctica:",
            areas: [
              
              "Alianzas Estratégicas ",
              "Leyes Anticorrupción",
              "Ambiental",
              "Licitaciones Públicas",
              "Conversiones",
              "Litigio Administrativo",
              "Competencia Económica",
              "Litigio Laboral",
              "Consultoría Laboral",
              "Corporativo",
              "Migratorio",
              "Cumplimiento ESG",
              "Minería",
              "Energía",
              "Propiedad Intelectual",
              "Financiamiento de proyectos",
              "Reestructuras Financieras",
              "Financiero",
              "Reestructuras y Concursos",
              "Gas y Petroleo",
              "Hoteles y Hospitalidad",
              "Salud",
              "Industria Automotriz",
              "Seguridad Social",
              "Industria Farmacéutica",
              "Seguros",
              "Industria Fílmica",
              "Telecomunicaciones",
              "Infraestructura",
              "Transporte y Aviación",
              "Inmobiliario",
              "Verificación de Antecedentes",
              "Inversiones de Capital",
            
            ],
          },
          Publicaciones: {
            id: "1",
            enfoque: "Nuestro Enfoque:",
            title: "Nuestras Oficinas",
            title2: " Otras Ubicaciones",
            title3: "https://ballpllc.com/",

            subtitle:
              "Altos estándares de calidad y una amplia gama de servicios",
            description:
              "Cada uno de los socios, asociados y pasantes del Bufete se Especializan en una o más de las siguientes áreas de práctica:",
            areas1: [
              // {
              //   name: "Mexico City, Mexico",
              //   link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
              //   dir: "Bosque de Ciruelos 130-501, Col. Bosques de las Lomas",
              //   ciudad: "Mexico City, Mexico 11700",

              // },
              {
                name: "Houston, Texas USA",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Anuncio-Servicios-Compilance.pdf",
                dir: "1400 Broadfield Boulevard, Suite 200",
                ciudad: "Houston, TX 77084",
                tel: "+1.281.519.3335"
              },
              {
                name: "Dallas, Texas USA",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Anuncio-Servicios-Compilance.pdf",
                dir: "5445 La Sierra Drive, Suite 415",
                ciudad: "Dallas, Texas 75231",
                tel: "+1.281.519.3335"
              },
              {
                name: "Washington, D.C. USA",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "1717 Pennsylvania Avenue NW, 10th Floor",
                ciudad: "Washington, D.C. 20006",
                tel: "+1.202.559.9100"
              },
              {
                name: "Jakarta, Indonesia",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "The City Tower, Level 12-1N, Jalan MH Thamrin No 81, Dukuh Atas",
                ciudad: "Jakarta 10310, DKI, Indonesia",
           
              },
              // {
              //   name: "Mexico City, Mexico",
              //   link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
              //   dir: "Bosque de Ciruelos 130-501, Col. Bosques de las Lomas",
              //   ciudad: "Mexico City, Mexico 11700",

              // },
              {
                name: "Santa Cruz, Bolivia",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "Calle 21 de mayo No. 633 ",
                ciudad: "Santa Cruz, Bolivia",
             
              },
              {
                name: "Sao Paulo, Brazil",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "760, Princesa D’Oeste Avenue, Zip 13100-040",
                ciudad: "Campinas – São Paulo",
             
              },
              {
                name: "Buenos Aires, Argentina",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "Buenos Aires Av. Córdoba N° 3029, Piso 2, Oficina C. ",
                ciudad: "Buenos Aires, Argentina",
             
              },
              {
                name: "Mendoza, Argentina",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Boletin-Informativo-dic.pdf",
                dir: "Agustín Álvarez 589",
                ciudad: "Mendoza, Argentina",
              },
              {
                name: "Madrid, Spain",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Boletin-Informativo-dic.pdf",
                dir: "Calle Jorge Juan, 65 2º, (65 Jorge Juan Street, Second Floor)",
                ciudad: "Madrid, Spain 28009",
              },
             
            ],

            areas2:[
              {
                
                  name: "Mexico City, Mexico",
                  link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                  dir: "Bosque de Ciruelos 130-501, Col. Bosques de las Lomas",
                  ciudad: "Mexico City, Mexico 11700",
                  mail:"contacto@ihma.law",
                  tel: "+52.55.5292.6191",
  
                
                
              },

            ]
          },
          clientesData: {
            titulo: "Clientes Representativos",
            descripcion:
              "Los abogados de IHMA son reconocidos por su innovación legal y su excelente servicio a clientes",
            clientesList: [
              "1ero Dinero",
              "Aegerion Pharmaceuticals",
              "Aeromexico Cargo",
              "Aeroservicios Especializados",
              "Aggreko",
              "AIM",
              "Allrig",
              "Alto",
              "Amerex Brokers",
              "AMK Healthcare",
              "Arsenal Capital Partners",
              "AXA",
              "Beesion Technologies México",
              "Beyond Trust Software Inc.",
              "Bristol-Myers Squibb de México",
              "Cámara Mexicana de la industria de la Construcción",
              "Capital Inmobiliario",
              "Columbia Laboratorios",
              "Combustibles Masal",
              "Corporativo Laudex",
              "Cree Ser México PF",
              "Dade Behring",
              "Daito Gyourui Co.",
              "Datacard",
              "Digital Entertainment",
              "Ding",
              "Echostar",
              "Elastomeros Taza",
              "Empacadora Sabadell",
              "Equipos Médicos Vizcarra",
              "Expo Pack México",
              "Gate Gourmet",
              "Georesearch",
              "GIFF",
              "Golden Gate Capital",
              "Groke",
              "Grupo Quiper",
              "Grupo Rendichicas",
              "GTA Telecom de México",
              "Guthy Renker",
              "Hanosil International",
              "HIG Capital Partners",
              "High Level Films",
              "Hoteles City Express",
              "Huatan",
              "HYT Capital",
              "Industria de la Construcción",
              "IRASA Fortec",
              "Jos A. Bank Clothiers",
              "Joy Global México",
              "Krispy Kreme Doughnut Corporation ",
              "Lean Solutions Group",
              "Lease Plan México",
              "Lek Securites Corporation",
              "Lemon Films",
              "Lemon Media",
              "Leo Pharmaceuticals",
              "Lexus",
              "Maasa México",
              "Marking Services",
              "McCormik México",
              "McCormik Pesa",
              "Meyn Beheer, B.V.",
              "Movilab",
              "MW Rental Power",
              "Nova Condesa",
              "Orca Mining",
              "Packaging Machinery",
              "Manufacturers Institute Inc.",
              // "Pandora Jewelry",
              "PC Capital",
              "Peco Pallet México",
              "Pitney Bowes",
              "Powermate Corp.",
              "Sagem Securité",
              // "Schneider México",
              "Siemens",
              "Stewart Connector Systems",
              "Sun Capital Ventures",
              // "Takeda",
              "Tamoin",
              "Tower One Wireless",
              "Toyota",
              "Tresmontes Lucchetti México",
              "Truelock",
              "United Parcel Service (UPS)",
              "WFI",
              "Winco"
            ],
          },
        },
        // ----------------------------------------------------------------------------------------------------
        en:
        {
          // reconocimientos: {
            
          //   title: 'Firmas Boutique Especializadas en la Implementación del Sistema Integral de Cumplimiento ESG',
          //   subtitle: '',
          //   details1: 'El Consorcio se integra de una serie de firmas boutique que combinan la experiencia profesional de sus directores, asociados y junta de asesores en el despliegue del Sistema Integral de Cumplimiento (“SIC”) - ESG para corporaciones cotizadas y privadas.',
          //   details2: 'Nuestro conjunto de servicios altamente especializado incluye asuntos regulatorios, de derecho corporativo y de valores, sistemas de gestión de riesgos ambientales, estándares de gobernanza y responsabilidad social, respaldados por una experiencia práctica sustancial en derecho corporativo, gestión de inversiones, operaciones industriales y comunicaciones corporativas.',
          //   details3: 'Somos pioneros en el desarrollo de un Sistema Integral de Cumplimiento ESG y hemos desarrollado una metodología robusta y sistemática para el despliegue y adopción del SIC ESG y estándares de informes para nuestros clientes.',
          //   details4: 'Nuestros clientes se benefician de nuestra experiencia para mejorar significativamente su cultura de cumplimiento dentro de un marco sistemático que posee métricas claras e hitos concretos, los cuales son esenciales para la búsqueda de ventajas competitivas duraderas y un crecimiento sostenible.',
          //   title2: 'Nuestro Equipo de Expertos ESG',
          //   member1: 'Mauricio Ibáñez',
          //   member1Title: 'Socio Director',
          //   member1Desc: 'Socio fundador de IHMA - Ibáñez Hoffmann Mendoza Andrade, firma legal especializada en gobierno y derecho corporativo; Ex Secretario Consejo Grupo México S.A.B. y miembro consejero de diversas corporaciones. Licenciado en derecho por la Universidad Iberoamericana con Master en derecho en el ámbito bancario, financiero y corporativo, por Columbia University - School of Law.',
          //   member2: 'Salvador Nava',
          //   member2Title: 'Socio Director',
          //   member2Desc: 'Socio fundador DPTA; graduado en derecho por la Universidad Iberoamericana; Doctor en derecho por la Universidad Complutense de Madrid, especialista en derecho constitucional y ciencias políticas por el Centro de Estudios constitucionales de Madrid. Catedrático y miembro de Conacyt así como de la comisión técnica del Grupo Oaxaca a cargo de la redacción de la primera versión de la Ley Federal de Transparencia y Acceso Público a la Información de Gobierno.',
          //   member3: 'Jorge Fernández',
          //   member3Title: 'Socio Director',
          //   member3Desc: 'Abogado especialista en Cumplimiento ESG, actualmente of-counsel de la firma Arent Fox LLP basada en Washington D.C., y Socio Director de la firma consultora especialista en Compliance-ESG JAFD Consulting LLP, previamente Director General Jurídico para Mars Inc., y previamente para Motorola Latino América. Abogado y Doctor en derecho por la Escuela Libre de Derecho.',
          //   member4: 'Alejandro H. Garza',
          //   member4Title: 'Socio Director',
          //   member4Desc: 'Alejandro aporta más de 25 años de experiencia en gestión de inversiones y banca de inversión, y se especializa en estrategias de capital global sustentables/ESG a largo plazo. Anteriormente (2007-2016) PM y socio director de Ashmore Equities US, con más de $11 billones en activos bajo administración. MBA y BSc Tecnológico de Monterrey.',
          //   member5: 'Fabiola Pastran',
          //   member5Title: 'Asociada',
          //   member5Desc: 'Analista Ninter Group USA (Miami, Florida); Analista Guangzhou Juvan LLC, China y Venezuela. BSc., MBA University of Miami, CFA ESG Investing.',
          //   member6: 'Laura Martinez',
          //   member6Title: 'Socia',
          //   member6Desc: 'Laura colaboró durante casi 15 años con la calificadora de riesgo S&P Global Ratings en México, participando como directora y analista líder en el grupo de calificaciones corporativas. Profesor asociado ITESM Campus Querétaro desde 2020. BSc Tecnológico de Monterrey. Candidato CFA Nivel 3.',
          //   title0:'Antecedentes y Ecosistema ESG',
          //   detailsT0a: 'El concepto de ESG fue lanzado en enero de 2004 por el entonces SG de la ONU, Kofi Annan (Corporación Financiera Internacional (IFC)), dirigiéndose a los 50 directores ejecutivos de las instituciones financieras más grandes del mundo para integrar los estándares de cumplimiento en su práctica como inversores institucionales.            ',
          //   detailsT0b: 'En la actualidad, la mayoría de los grandes inversores institucionales globales (BlackRock, Robeco SAM, IFC, entre otros) alientan de forma proactiva a las empresas en las que invierten, cotizadas o privadas, a adherirse a los estándares de cumplimiento de ESG. Por esta razón, el marco ESG se ha convertido en sinónimo del más alto estándar de cumplimiento y gobierno corporativo a nivel mundial.            ',
          //   detailsT0c: 'En México, el 22 de junio de 2020, la BMV en asociación con “S&P Dow Jones Indices” lanzó su índice ESG para promover estándares de buen gobierno corporativo y sustentabilidad.             ',
          //   detailsT0d: 'A la fecha, existen varios índices y agencias calificadoras (S&P, MSCI, ISS, EIKON, entre otras) que evalúan e informan el desempeño ESG de las corporaciones. Algunos elementos ESG han ganado tanta importancia, que se están convirtiendo en parte de las regulaciones de los mercados de valores en varias jurisdicciones, incluida la Unión Europea.            ',
          //   detailsT0e: 'GRI (Global Reporting Initiative) es una institución global independiente con sede en los Países Bajos, fundada en Boston en 1997 en respuesta al desastre del Exxon Valdez, que proporciona informes gratuitos  sobre los estándares de sustentabilidad para las empresas.            ',
          //   detailsT0f: 'La combinación del marco ESG con los estándares de informes GRI, proporciona a las corporaciones y a sus diferentes partes interesadas un lenguaje común de cumplimiento y constituye un poderoso conjunto de herramientas para la sustentabilidad y la creación de valor.            ',
          //   title3: 'Racional Estratégico del Sistema Integral de Cumplimiento ESG',
          //   detailsT3a: 'Hoy las empresas reconocen la importancia estratégica de alcanzar y mantener los más altos estándares de Cumplimiento Corporativo con los objetivos de:',     
          //   detailsT3b: 'contingencias y mitigar riesgos tangibles e intangibles.',
          //   detailsT3b1: '• Prevenir ',
          //   detailsT3c: 'a la empresa, sus activos, a sus accionistas, sus ejecutivos y empleados, así como su entorno.',
          //   detailsT3c1: '• Proteger ',
          //   detailsT3d: 'a la empresa de la manera más favorable ante reguladores y diferentes “stakeholders”.',
          //   detailsT3d1: '• Posicionar ',
          //   detailsT3e: 'Tiene un impacto tangible en el costo de capital de las corporaciones, contribuye a atraer y retener talento que a su vez contribuye a la creación de valor económico.',
          //   detailsT3f: 'El ',
          //   detailsT3g: 'Sistema Integral de Cumplimiento ESG ',
          //   detailsT3h: 'abarca de forma global muchos de los conceptos que forman parte las regulaciones en diversas jurisdicciones, por lo cual prepara de forma robusta a la empresa y sus directivos y consejeros ante contingencias legales muy serias:',
          //   detailsT3i: '• Leyes y regulaciones bancaria',
          //   detailsT3j: '• Foreign Corrupt Practices Act (FCPA) de EUA',
          //   detailsT3k: '• UK Antibribery Act',
          //   detailsT3l: '• OCDE Reporting Standards',
          //   detailsT3m: '• Ley Anticorrupción Brasil',
          //   detailsT3n: '• Sistema Nacional Anticorrupción México',
          //   detailsT3o: '• LILCCPD de España',
          //   detailsT3p: '• Tratado ONU',
          //   detailsT3q: '• Aspectos del USMCA (Anteriormente TLCAN)',
          //   detailsT3r: '',
          //   title4: 'El Ecosistema ESG en Funcionamiento',
          //   imagenTitle4: `${agencias}`,
          //   title5: 'Diseño de un Sistema Integral de Cumplimiento ',
          //   imagenTitle5: `${cumplimiento}`,
          //   title6: 'Marco de Monitoreo y  Medición del Sistema Integral de Cumplimiento',
          //   imagenTitle6: `${areas}`,
          //   title7: 'Nuestra Metodología',
          //   imagenTitle7: `${metodologia}`,
          //   title8: 'Organización “Tripartita”            ',
          //   imagenTitle8: `${organizacion}`,
          //   title9: 'Nuestra “Matriz Semáforo ESG"',
          //   imagenTitle9: `${matriz}`,
          //   title10: 'Proceso de Revisión - Creación - Adopción - Divulgación',
          //   imagenTitle10: `${impacto}`,
          //   title11: 'BlackRock: Carta de Larry Fink a los Directores Ejecutivos 2022            ',
          //   textTitle11: 'En 2018, exhorté a todas las empresas a expresar su propósito y la manera en que este beneficia a todos los stakeholders, como serían accionistas, empleados, clientes y las comunidades en las que operan. A lo largo de 2020 vimos cómo las compañías con propósito y mejores perfiles ambientales, sociales y de gobierno corporativo (ESG) lograron un mejor desempeño que sus pares. Durante 2020, el 81% de índices sostenibles, de una selección mundialmente representativa, tuvo mejor desempeño que los índices de referencia. Este mejor desempeño fue aún más marcado durante la desaceleración en el primer trimestre, otro ejemplo de la resiliencia de los fondos sostenibles que también hemos observado en desaceleraciones anteriores^4. Y la amplia gama de opciones de inversión sostenibles continuará despertando el interés de los inversionistas por estos fondos, como lo vimos en 2020.',
          //   textTitle12: 'Desde enero a noviembre del 2020, los inversionistas en fondos de inversión y ETFs invirtieron USD 288.000 millones globalmente en activos sostenibles, un incremento del 96% con respecto a todo el 2019¹. Creo que esto representa el comienzo de una transición larga pero rápidamente acelerada – una que ocurrirá durante muchos años y transformará los precios de todo tipo de activos. Sabemos que el riesgo climático es un riesgo de inversión. Pero también creemos que la transición climática presenta una oportunidad de inversión histórica.',
          //   textTitle13: 'El mundo avanza hacia las cero emisiones netas y BlackRock considera que la mejor forma de apoyar a nuestros clientes es estando a la vanguardia de esa transición. Hoy en día somos carbono-neutral en nuestras propias operaciones y estamos comprometidos con apoyar el objetivo de cero emisiones netas de gases de efecto invernadero para 2050 o antes. Ninguna compañía puede planear más de treinta años, pero creemos que todas las compañías - incluyendo BlackRock - deben empezar a abordar la transición hacia las cero emisiones netas. En este sentido, estamos dando varios pasos para ayudar a los inversionistas a preparar sus portafolios para un mundo de cero emisiones netas, entre ellos la captura de oportunidades creadas por la transición hacia este objetivo.',
          //   textTitle14: 'Pero los resultados van más allá. No solo los índices ESG de mercado general exhiben un mejor desempeño que sus contrapartes, sino que, dentro de los sectores, desde el automovilístico y el bancario hasta el de petróleo y gas, notamos otra divergencia: las compañías con mejores perfiles ESG tienen mejor desempeño que sus pares, pues gozan de una “prima de sostenibilidad”⁵.',
          //   textTitle15: 'Aunque es inevitable que la transición sea compleja y difícil, es esencial forjar una economía más resiliente que beneficie a más personas. Tengo gran optimismo con respecto al futuro del capitalismo y la salud de la economía, no a pesar de la transición energética, sino gracias a ella.',

          reconocimientos: {
            title: 'Background and the ESG Concept',
            subtitle: '',
            details1: 'The ESG concept was launched in January 2004 by the then UN SG Kofi Annan (with the support of the IFC) by addressing the 50 CEOs of the world`s largest financial institutions to integrate compliance standards into their practice as institutional investors, today, it is synonymous with the highest standards of corporate governance globally and has become a classification for companies that encompasses more than $20 trillion worldwide.',
            details2: 'Today most large global institutional investors (BlackRock, Robeco SAM, the IFC among others) proactively encourage companies in which they invest, listed or private, to adhere to ESG compliance standards. ',
            
           // details2: 'Our highly specialized set of services include corporate and securities law and regulatory matters, environmental risk management systems, governance and social responsibility standards, backed by substantial hands-on experience in corporate law, investment management,  industrial operations and corporate communications.',
            //details3: 'We are pioneers in the development of Integral ESG compliance systems and have developed a robust and systematic methodology for the deployment and adoption of Integral ESG systems and reporting standards for our clients.',
            //details4: 'Our clients leverage our expertise to significantly enhance their underlying compliance culture within a systematic framework that has clear metrics and concrete milestones, which are essential in the pursuit of lasting competitive advantages and for sustainably high growth.',
            title2: 'Our Team of ESG Experts',
            member1: 'Mauricio Ibáñez',
            member1Title: 'Parter',
            member1Desc: 'Founding Partner of IHMA – Ibáñez Hoffmann Mendoza Andrade, a law firm specializing in corporate law and corporate governance. Law degree from the Universidad Iberoamericana and Masters Degree, Banking, Finance, Corporate Law from Columbia University, School of Law. Former Secretary of the Board of Grupo México, S.A.B. de C.V. (mining, railroads and infrastructure conglomerate) and other companies.',
            member2: 'Salvador Nava',
            member2Title: 'Partner',
            member2Desc: 'Founding partner of DPTA; graduate of the Faculty of Law of the Universidad Iberoamericana; Doctor of Law from the Complutense University of Madrid, and specialist in Constitutional Law and Political Science from the Center for Constitutional Studies of Madrid. Professor, member of Conacyt, as well as of the Technical Commission of the Oaxaca Group that drafted the first Federal Law of Transparency and Access to Public Government Information.',
            member3: 'Jorge Fernández',
            member3Title: 'Partner',
            member3Desc: 'ESG-compliance attorney, currently Of counsel of Arent Fox LLP based in Washintong, D.C.; previously former Of Counsel of Squire Patton Boggs in Washington DC, previously General Counsel for Mars, General Counsel for Motorola. Lawyer (Doctor of Law / JD) from the Escuela Libre de Derecho.',
            member4: 'Alejandro H. Garza',
            member4Title: 'Partner',
            member4Desc: 'Alejandro brings 25 years of experience in investment management and investment banking, specializing in global equity long-only strategies.  Previously (2007-2016) PM and managing partner with Ashmore Equities US, with over $ 11 billion in assets under management. Previously Alejandro also worked in investment banking and corporate finance. MBA and BSc Tecnológico de Monterrey.',
            member5: 'Fabiola Pastran',
            member5Title: 'ESG Specialist',
            member5Desc: 'Analyst Ninter Group USA (Miami, Florida); Analyst Guangzhou Juyuan LLC, China and Venezuela. BSc, MBA University of Miami, CFA ESG Investing',
            member6: 'Laura Martinez',
            member6Title: 'ESG Specialist',
            member6Desc: 'Laura collaborated for nearly 15 years with the credit rating agency S&P Global Ratings in Mexico, participating as director and lead analyst in the corporate ratings group. Professor at the ITESM Campus Querétaro since 2020. BSc Tecnológico de Monterrey. CFA Level 3 Candidate',
            title0:'A Consortium of Leading Boutique Firms Specializing in the Deployment of Integral ESG-Compliance Systems',
            detailsT0a: 'The Consortium combines the professional expertise of its directors, associates and board of advisors in the deployment of best-in-class Integral ESG compliance systems for listed and private corporations.',
            detailsT0b: 'Our highly specialized set of services include corporate and securities law and regulatory matters, environmental risk management systems, governance and social responsibility standards, backed by substantial hands-on experience in corporate law, investment management,  industrial operations and corporate communications.',
            detailsT0c: 'We are pioneers in the development of Integral ESG compliance systems and have developed a robust and systematic methodology for the deployment and adoption of Integral ESG systems and reporting standards for our clients.',
            detailsT0d: 'Our clients leverage our expertise to significantly enhance their underlying compliance culture within a systematic framework that has clear metrics and concrete milestones, which are essential in the pursuit of lasting competitive advantages and for sustainably high growth.',

           
         //   detailsT0a: 'The ESG concept was launched in January 2004 by the then UN SG Kofi Annan (with the support of the IFC) by addressing the 50 CEOs of the world`s largest financial institutions to integrate compliance standards into their practice as institutional investors.',
           // detailsT0b: 'Today most large global institutional investors (BlackRock, Robeco SAM, the IFC among others) proactively encourage companies in which they invest, listed or private, to adhere to ESG compliance standards. For this reason, the ESG framework has become synonymous with the highest standard of compliance and corporate governance globally.',
            //detailsT0c: 'In Mexico, on June 22, 2020, the BMV in association with “S&P Dow Jones Indices” launched its ESG index to promote standards of good corporate governance and sustainability.',
            //detailsT0d: 'To date, there are various indexes and rating agencies (S&P, MSCI, ISS, EIKON, among others) that evaluate and report ESG performance for corporations. Some ESG items have gained such importance that are becoming part of securities markets regulations in various jurisdictions including the European Union.',
         //   detailsT0e: 'The GRI (Global Reporting Initiative) is an independent global institution currently headquartered in the Netherlands - founded in Boston in 1997 in response to the Exxon Valdez disaster – that provides free sustainability reporting standards for corporations.',
           // detailsT0f: 'The combination of the ESG framework with the GRI reporting standards provides corporations and their different stakeholders with a common language of compliance and constitutes a powerful set of tools for sustainability and value creation.',
            // detailsT3a: 'Today companies recognize the strategic importance of achieving and maintaining the highest standards of Corporate Governance and Compliance in order to:',     
            // detailsT3b: 'contingencies and mitigate tangible and intangible risks.',
            // detailsT3b1: '• Prevent ',
            // detailsT3c: 'the company, its assets, its shareholders, its executives and employees, as well as the environment.',
            // detailsT3c1: '• Protect ',
            // detailsT3d: 'the company in in optimal fashion before regulators and its different stakeholders.',
            // detailsT3d1: '• Position ',
            // detailsT3e: 'Has a tangible impact on corporations’ cost of capital, contributes to attract and retain talent which in turn contributes to the creation of economic value.',
            // detailsT3f: 'An ',
            // detailsT3g: 'Integral ESG Compliance System',
            // detailsT3h: 'comprehensively covers regulatory matters in various jurisdictions in such a way that prepares the company and its directors to deal with very serious legal contingencies globally:',
            // detailsT3i: '• Foreign Corrupt Practices Act (FCPA) de EUA',
            // detailsT3j: '• UK Antibribery Act',
            // detailsT3k: '• OCDE Reporting Standards',
            // detailsT3l: '• Ley Anticorrupción Brasil',
            // detailsT3m: '• Sistema Nacional Anticorrupción México',
            // detailsT3n: '• LILCCPD de España',
            // detailsT3o: '• UN Accord',
            // detailsT3p: '• USMCA Standards',
            // detailsT3r: '',
            title4: 'The ESG Ecosystem at Work',
            imagenTitle4: `${agencias}`,
            title5: 'Our Integral ESG Compliance System Design',
            imagenTitle5: `${cumplimiento}`,
            title6: 'Monitoring and Measuring the ESG Compliance System',
            imagenTitle6: `${areas}`,
            title7: 'Our Methodology',
            imagenTitle7: `${metodologiaIng}`,
            title8: '“Tripartite” Layout',
            imagenTitle8: `${organizacion}`,
            title9: 'Our Proprietary “ESG Traffic Light Matrix” to Monitor our Clients’ Progress Towards ESG Compliance Excellence"',
            imagenTitle9: `${matriz}`,
            title10: 'Ratio Rating: Create - Adopt - Divulge',
            imagenTitle10: `${impacto}`,
            title11: 'BlackRock: Larry Fink´s 2022 Letter to CEO',
            textTitle11: 'EThe world is moving to net zero, and BlackRock believes that our clients are best served by being at the forefront of that transition. We are carbon neutral today in our own operations and are committed to supporting the goal of net zero greenhouse gas emissions by 2050 or sooner. No company can easily plan over thirty years, but we believe all companies - including BlackRock - must begin to address the transition to net zero today. We are taking a number of steps to help investors prepare their portfolios for a net zero world, including capturing opportunities created by the net zero transition.',
            textTitle12: 'In 2018, I wrote urging every company to articulate its purpose and how it benefits all stakeholders, including shareholders, employees, customers, and the communities in which they operate. Over the course of 2020, we have seen how purposeful companies, with better environmental, social, and governance (ESG) profiles, have outperformed their peers. During 2020, 81% of a globally-representative selection of sustainable indexes outperformed their parent benchmarks.This outperformance was even more pronounced during the first quarter downturn, another instance of sustainable funds resilience that we have seen in prior downturns.And the broader array of sustainable investment options will continue to drive investor interest in these funds, as we have seen in 2020.',
            textTitle13: 'But the story goes deeper. Its not just that broad-market ESG indexes are outperforming counterparts. Its that within industries - from automobiles to banks to oil and gas companies - we are seeing another divergence: companies with better ESG profiles are performing better than their peers, enjoying a "sustainability premium"',
            textTitle14: 'This is why I write to you each year, seeking to highlight issues that are pivotal to creating durable value - issues such as capital management, long-term strategy, purpose, and climate change. We have long believed that our clients, as shareholders in your company, will benefit if you can create enduring, sustainable value for all of your stakeholders.',
            textTitle15: 'As you issue sustainability reports, we ask that your disclosures on talent strategy fully reflect your long-term plans to improve diversity, equity, and inclusion, as appropriate by region. We hold ourselves to this same standard.',
            textTitle16: 'From January through November 2020, investors in mutual funds and ETFs invested $288 billion globally in sustainable assets, a 96% increase over the whole of 2019.4 I believe that this is the beginning of a long but rapidly accelerating transition - one that will unfold over many years and reshape asset prices of every type. We know that climate risk is investment risk. But we also believe the climate transition presents a historic investment opportunity.',
            textTitle17: 'While the transition will inevitably be complex',
            



          },
          alianzas: {
            title: 'Strategic Alliances',
            subtitle: 'IHMA International Capabilities',
            details1: 'Today we live in a world focused on global markets and modern business transactions that encompass elements of different domestic and foreign jurisdictions.  The IHMA International Practice capability helps clients navigate these often complex and uncertain markets and their legal systems.  We help clients to promote their international business interest in Mexico and relevant markets.  IHMA attorneys have studied and worked abroad, speak foreign languages, and know different business cultures.  In addition, the Firm has a vast network of lawyers and foreign law firms around the globe to assist the firm in providing one-stop shop solutions for its clients wanting to take advantage of these global markets and promote their international business interests.',
            details2: 'IHMA and Leger Ketchum & Cohoon, PLLC (LKC), a leading law firm in the Houston area with offices in The Woodlands, Boerne-San Antonio and Dallas, Texas have teamed up as part of a strategic alliance to handle international trade and cross-border matters in both the US and Mexico.  By joining forces in strategic alliance, the combined resources of IHMA attorneys admitted in Mexico and LKC lawyers admitted in Texas and other US jurisdictions, allows us to provide integrated and comprehensive legal advice in any corporate, trade, labor or other matter governed by the United States - Mexico - Canada Agreement (USMCA) that superseded and replaced NAFTA.  ',
            details3: 'After working for almost two decades as General Counsel for several major multinational companies in the automotive, pharmaceutical, nutritional, energy and utility industries managing corporate responsibilities in Mexico and Latin America, Diego Andrade Max joined IHMA in 2017 and led our efforts to open a liaison office in the Woodlands, Texas in 2018.  The purpose of this office is to be closer to our US based clients doing business in Mexico.',
            details4: 'The next step has resulted in a strategic alliance with a Texas firm because our foreign clients doing business in Mexico often inquired about access to services in Texas and Mexican high net-worth individual clients have pursued ventures in Texas.  Our partner, Diego Andrade manages the IHMA-LKC alliance and splits his time between Houston and Mexico City. '

          },
          footer: {
            terms: 'Terms and Conditions',
            politicy: 'privacy policies',
            contact: 'Contact',
            join: 'Join our',
            social: 'Social Networks',
          },
          home: {
            heroDescription1: 'International',
            heroDescription2: 'Legal Consultancy',
            greyDecorate: 'Thanks to our integration with the firm BALL – PLLC, in June 2023, the Firm now under the brand “BALL – IHMA” has the possibility to extend it’s services and capabilities in strategic locations in the United States of America, Southamerica, Europe, Asia and Middle Eastern.',
            details1a: 'was envisioned as a full service law firm servicing foreign and local clients used to receiving top quality legal services from the best and largest international law firms, but with such a personal degree of attention in all matters entrusted to it by the clients, that clearly distinguishes it from the larger traditional law firms.         ',
            details1b: 'The education and employment backgrounds of the members of the firm in said latitudes, allows BALL – IHMA to better understand the clients needs, the legal issues involved on the both sides of a crossborder transaction and to deliver quality and timely responses as expected by the most demanding clients.        ',
            details2a: 'Present-day business perspective',
            details2b: 'One of our main goals consists in proactive approach to our clients’ matters, providing practical and reliable advice in those matters entrusted to the Firm. The quality of our services meets the utmost demands of a globalized world in constant change.        ',
            dir1: '1095 Evergreen Circle',
            dir2: 'Suite 200 - 432',
            dir3: 'The Woodlands, TX 77380',
            office: 'Office Woodlands, Texas        ',
            idiomaTitle: "Language:"
          },
          equipoLegal:
          {
            title: 'Legal Team',
            details: 'IHMA attorneys are recognized for their legal innovation and excellent client service',
          },
          equipoLegal2:
          {
            title: 'Legal Team',
            details: 'IHMA attorneys are recognized for their legal innovation and excellent client service',
          },
          nav:
          {
            link1: 'The Firm',
            link2: 'Practices',
            link3: 'Team',
            link31: 'Partners',
            link32: 'Associates',
            link4: 'Clients',
            link5: 'Consortium ESG',
            link6: 'Strategic Alliances',
            link7: 'Our Locations',
            actual: 'Sp'
          },
          dataSocios2:
            [
              {
                id: '1',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: null,
                areasTitle: 'Practice Areas',
                nombre: 'María Julia Bugnest',
                image: `${MariaJuliaRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad de Buenos Aires, (UBA), Argentina.  (2008)        ',
                ],
                expProfesional: ['Microsoft, South Cone. Argentina. (2007-2009)        ',
                  'Abeledo Gottheil Abogados. Argentina. (2009-2010)',
                  'Estudio O´Farrell Abogados. Argentina. (2010-2011)                        ',
                  'ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-present).                       ',
                ],
                areasDePractica: [
                  'Corporate Law, Mergers and Acquisitions.',
                ],
                imagenCuadrada: `${Maria}`,
                estatusSocio: "Associate",
                nacSocio: "Buenos Aires, Argentina. June 15, 1983",
                titulacionSocio: "Admitted, 2008.",
                idiomaSocio: "Spanish, English ",
                idiomaTitle: "Language:",
                emailSocio: " jbugnest@ihma.law ",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/MariaJuliaBugnest.vcf"
              },
              {
                id: '2',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: null,
                areasTitle: 'Practice Areas',
                nombre: 'Eduardo Chávez Pulido',
                image: `${EduardoPulidoRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Tecnológica de México (J.D. 2002)',
                ],
                expProfesional: ['Martín del Campo y Asociados, S.C. (2002-2003)',
                  'Cardoso y Asociados, S.C. (2003-2004)',
                  'Buenfil & Hoffmann (2004-2006)',
                  'Ibáñez & Maroto, S.C., Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-present).',
                ],
                areasDePractica: ['Administrative Law, Family Law, Civil Law, Criminal Law, Commercial Law, Labor and Employment Law, Social Security.',
                ],
                imagenCuadrada: `${Eduardo}`,
                estatusSocio: "Associate",
                nacSocio: "Born Mexico City..            ",
                titulacionSocio: "Admitted, 2003, Mexico. ",
                idiomaSocio: "Spanish and English (Basic)",
                idiomaTitle: "Language:",
                emailSocio: " echavez@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/EduardoChavezPulido.vcf"
              },
              {
                id: '3',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: null,
                areasTitle: 'Practice Areas',
                nombre: 'Adrián Monter Alonso ',
                image: `${AdrianC}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Panamericana – law student (present)',
                ],
                expProfesional: ['BALL-IHMA (january 2024-present).',
                     
                ],
                areasDePractica: [
                  'Corporate law',
                ],
                imagenCuadrada: `${Adrian}`,
                estatusSocio: "Associate",
                nacSocio: "Born Mexico City. November 9, 2023.",
                titulacionSocio: "            ",
                idiomaSocio: "Spanish",
                idiomaTitle: "Language:",
                emailSocio: "amonter@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/JosueHernandezBalderas.vcf"


              },
              {
                id: '4',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: null,
                areasTitle: 'Practice Areas',
                nombre: 'Gerardo Javier Salomón Ramón',
                image: `${GerardoJavierSalomon}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Insurgentes Plantel Tlalpan (2021).',
                ],
                expProfesional: ['Ronquillo & Hernández Abogados S.C. (2019 –2022) ',
                  'Anzures y Morales Abogados, S.C. (2022)',
                  'IHMA Abogados y Consultores, S.C. (2023), BALL-IHMA (Junio 2023-present).',
                ],
                areasDePractica: ['Labor and Employment Law, Social Security Law.',
                ],
                imagenCuadrada: `${Gerardo}`,
                estatusSocio: "Associate",
                nacSocio: "Born Mexico City. December 22, 1999.            ",
                titulacionSocio: "Admitted, 2023, Mexico.",
                idiomaSocio: "Spanish",
                idiomaTitle: "Language:",
                emailSocio: "  gsalomon@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/AnaGabrielaNunezRodriguez.vcf"


              },
              {
                id: '5',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: null,
                areasTitle: 'Practice Areas',
                nombre: 'Jonathan Retana Pérez',
                image: `${JhonatanRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad de las Américas - UDLA (J.D., 2006)',
                ],
                expProfesional: ['Ortiz Noriega y González, S.C. (2005-2011)',
                  'Pronósticos para la Asistencia Pública (2011-2014)',
                  'Naviera Integral, S.A. de C.V. (2014-2017)                        ',
                  'Grupo Sacbes, S.C. (2017-2020)                        ',
                  'IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-present).'
                ],
                areasDePractica: [
                  'Administrative law, Civil Law, Corporate and Intellectual Property',
                ],
                imagenCuadrada: `${Jonathan}`,
                estatusSocio: "Associate",
                nacSocio: "Born Mexico City. June 24th, 1983.            ",
                titulacionSocio: "Admitted, 2010, Mexico. ",
                idiomaSocio: "Spanish, English, and French(basic)",
                idiomaTitle: "Language:",
                emailSocio: " jretana@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/JonathanRetanaPerez.vcf"
              },
              {
                id: '6',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: 'Member',
                areasTitle: 'Practice Areas',
                nombre: 'Erika Velázquez Blakely',
                image: `${ErikaRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Nacional Autónoma de México (Licenciatura 1997)',
                  'Universidad Nacional Autónoma de México / Diplomado en Derecho Corporativo (2019)',
                ],
                expProfesional: [
                  'Bufete De Presno y Asociados, S.C. (1994-1999)',
                  'Buenfil & Hoffmann, S.C. (1999-2004)',
                  'Ibáñez, Maroto, Schriever & Hoffmann, S.C. (2004-2007)',
                  'Ibáñez & Maroto, S.C., Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-present).',
                ],
                socioMiembroDe: ['American Bar Association',
                ],
                areasDePractica: ['Labor and Employment Law consulting and litigation, legal auditing, Social Security, Corporate- Labor.',
                ],
                imagenCuadrada: `${Erika}`,
                estatusSocio: "Associate",
                nacSocio: "Born Mexico City. April 4, 1974.            ",
                titulacionSocio: "Admitted, 1998, Mexico. ",
                idiomaSocio: "Spanish and English",
                idiomaTitle: "Language:",
                emailSocio: " ehoffmann@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              },
              {
                id: '7',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: 'Accreditations',
                areasTitle: 'Practice Areas',
                nombre: 'Yasiri Salazar Martínez',
                image: `${YasiriSalazar}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['Universidad Nacional Autónoma de México FES Cuatitlán (bachelors degree in accounting 2006) ',
                  'IEE Instituto de Especialización para Ejecutivos (Master in Taxation 2018)',
                  'Universidad Tecnológica de México (Law Degree – in progress)',
                ],
                expProfesional: [
                  'Ibáñez Maroto Schriever & Hoffmann, S.C. – Administrative Accountant (2008-2011), IHMA Abogados y Consultores, S.C. General Accountant (2011-2023), BALL-IHMA (Junio 2023-present).'
                ],
                socioMiembroDe: ['Asociación Mexicana de Contadores Públicos – CPA Certification',
                'Cámara Mexicana de la Industria de la Construcción – Oficial Instruction in SIROC',
                ],
                areasDePractica: ['Accounting, Tax, Social Security, Payroll Administration, Administrative Aspects in Specialized Services Accreditation (REPSE) and Affiliation of Construction Workers to the Mexican Social Security System (SIROC)',
                ],
                imagenCuadrada: `${Yasiri}`,
                estatusSocio: "Associate",
                nacSocio: "Born Mexico City. September 1, 1982.            ",
                titulacionSocio: "Admitted, 2006, Mexico. ",
                idiomaSocio: "Spanish and English (Basic)",
                idiomaTitle: "Language:",
                emailSocio: " ysalazar@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              },
              {
                id: '8',
                educacionTitle: '',
                expTitle: 'Professional Experience',
                socioTitle: '',
                areasTitle: 'Practice Areas',
                nombre: 'Eduardo Luz Guerra',
                image: `${EduardoLuz}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['',
                ],
                expProfesional: [
                  'Ibáñez & Maroto, S.C; Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022 - Paralegal), BALL-IHMA (Junio 2023-presente - Paralegal).'
                ],
                socioMiembroDe: ['',
                ],
                areasDePractica: ['Labor and Employment Law; Collective Bargaining Agreements and Union matters',
                ],
                imagenCuadrada: `${EduardoL}`,
                estatusSocio: "Associate",
                nacSocio: "Born Michoacan. December 10, 1975.            ",
                titulacionSocio: " ",
                idiomaSocio: "Spanish",
                idiomaTitle: "Language:",
                emailSocio: " eluz@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              },
              {
                id: '9',
                educacionTitle: '',
                expTitle: 'Professional Experience',
                socioTitle: '',
                areasTitle: 'Practice Areas',
                nombre: 'Jaqueline Santiago Torres',
                image: `${JaquelineC}`,
                imgTriangulo: `${triangulo}`,
                educacion: ['',
                ],
                expProfesional: [
                  'Barak Rabah Firma de Abogados S.C. (2020-2021); Lofton Servicios Integrales S.C (2021-2022); Adamantine (2022-2023); IHMA Abogados y Consultores, S.C. (2023); BALL-IHMA (June 2023-present).'
                ],
                socioMiembroDe: ['',
                ],
                areasDePractica: ['Labor and Civil law',
                ],
                imagenCuadrada: `${Jaqueline}`,
                estatusSocio: "Associate",
                nacSocio: "Born in Mexico City, Mexico. April 5, 1999.",
                titulacionSocio: "Admitted to practice, Mexico 2023. ",
                idiomaSocio: "Spanish",
                idiomaTitle: "Language:",
                emailSocio: " jsantiago@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Asociados/ErikaVelazquezBlakely.vcf"
              }



            ],
          dataSocios:
            [
              {
                id: "1",
                educacionTitle: "Education",
                expTitle: "Professional Experience",
                socioTitle: "Member",
                areasTitle: "Practice Areas",
                nombre: "Mauricio Ibáñez Campos",
                image: `${MauricioCamposRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: [
                  "Universidad Iberoamericana (Licenciatura, 1993)",
                  "Columbia University (LL.M., 1999)",
                ],
                expProfesional: [
                  "Baker & McKenzie, S.C. (1990-1994)",
                  "White & Case, S.C. (1995-1997, 1999-2001)",
                  "White & Case, LLP, New York (1997-1998)",
                  "Jáuregui, Navarrete, Nader y Rojas, S.C.",
                  "(International Associated Practice with Mayer, Brown, Rowe & Maw) (2001-2004)",
                  "Ibáñez & Maroto, S.C., Ibáñez Maroto Schriever & Hoffmann, S.C, IHMA Abogados y Consultores, S.C. (2004 – 2022), BALL-IHMA (Junio 2023-present).",
                ],
                socioMiembroDe: [
                  "Mexican Lawyers College",
                  'The Americas Society',
                  'Mexican Bar Association',
                  'American Bar Association',
                  'International Bar Association'
                ],
                areasDePractica: [
                  " Corporate Law, Mergers and Acquisitions, Project Finance, Private Equity, Workouts, Real Estate,      Telecommunications, Transportation, Mining, Energy, Oil & Gas.",
                ],
                imagenCuadrada: `${MauricioCamposCuadrado}`,
                estatusSocio: "Partner",
                nacSocio: "Born Mexico City. November 8, 1969.            ",
                titulacionSocio: "Admitted, 1994, Mexico.",
                idiomaSocio: "Spanish and English",
                idiomaTitle: "Language:",
                emailSocio: " mibanez@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/MauricioIbanezCampos.vcf"
              },
              {
                id: '2',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: 'Member',
                areasTitle: 'Practice Areas',
                nombre: 'Jorge Hoffmann Arias',
                image: `${JorgeAriasRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: ["Universidad Iberoamericana (J.D., 1991)"],
                expProfesional: [
                  "Zitro-Lex, S.A. de C.V. (1987-1989)",
                  "Alvarez, Cantón y Peters, S.C. (1989-1991)",
                  "Hoffmann y Asociados, S.C. (1991-1998)",
                  "Buenfil & Hoffmann, S.C. (1998-2005)",
                  "Ibáñez & Maroto, S.C; Ibáñez Maroto Schriever & Hoffmann, S.C",
                  "IHMA Abogados y Consultores, S.C. (2004 – 2022)",
                  "BALL-IHMA (Junio 2023-present)",
                ],
                socioMiembroDe: ["Mexican Bar Association"],
                areasDePractica: ["Labor and Employment Law", "Social Security"],
                imagenCuadrada: `${JorgeAriasCuadrado}`,
                estatusSocio: "Partner",
                nacSocio: "Born Mexico City. November 7, 1967.            ",
                titulacionSocio: "Admitted, 1995, Mexico. ",
                idiomaSocio: "Spanish and English",
                idiomaTitle: "Language:",
                emailSocio: " jhoffmann@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/JorgeHoffmannArias.vcf"
              },
              {
                id: '3',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: 'Member',
                areasTitle: 'Practice Areas',
                nombre: 'Jesús Mendoza Henríquez',
                image: `${JesusMendozaRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: [
                  ' Universidad Anáhuac (J.D., Mexico)',
                  'Pepperdine University (J.D., 2000)',
                  'Strauss Institute for Dispute Resolution, Pepperdine',
                  'University (LL.M in International Dispute Resolution, 2004),'
                ],
                expProfesional: [
                  "Buenfil & Hoffmann, S.C. (Clerk 1998-2000) ",
                  "Federal Competition Commission (Mexico, Legal Affairs Subdirector 2000-2001) ",
                  "Court Mediator Los Angeles County (Dispute Resolution Clinic Pepperdine University, 2003) ",
                  "Deputy General Counsel and General Counsel (2004-2008) ",
                  "Grupo Sacbes (Partner 2008-2018)",
                  "Ibáñez & Maroto, S.C, IHMA Abogados y Consultores, S.C. (2018 – 2022)",
                  "BALL-IHMA (Junio 2023-presente)"
                ],
                socioMiembroDe: ["Mexican Bar Association"],
                areasDePractica: [
                  "Antitrust Law",
                  "Administrative Law – consulting and litigation",
                  "Corporate Law",
                  "Telecommunications Law",
                  "Mergers and Acquisitions",
                  "Energy ",
                ],
                imagenCuadrada: `${JesusMendozaCuadrado}`,
                estatusSocio: "Partner",
                nacSocio: "Born Mexico City. December 10, 1975.            ",
                titulacionSocio: "Admitted, 2001, Mexico. ",
                idiomaSocio: "Spanish and English",
                idiomaTitle: "Language:",
                emailSocio: " jmendoza@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/JesusMendozaHenriquez.vcf"
              },
              {
                id: '4',
                educacionTitle: 'Education',
                expTitle: 'Professional Experience',
                socioTitle: 'Member',
                areasTitle: 'Practice Areas',
                nombre: 'Diego Andrade Max',
                image: `${DiegoMaxRedondo}`,
                imgTriangulo: `${triangulo}`,
                educacion: [
                  "Universidad Iberoamericana (J.D., 1992)",
                  "Universitaet Wien, Austria International and European EU Law, Doctorate, 1994)",
                  "The University of Chicago Law School (LL.M, 1996)",
                ],
                expProfesional: [
                  "Von Wobeser y Sierra, S.C. (Intern 1988–1992)",
                  "ICC International Court of Arbitration (Intern, 1993)",
                  "Mexican Federal Competition Commission (Deputy Legal Director 1994 -1995)",
                  "Gardner Carton Douglas, Chicago (Foreign intern 1996-1997)",
                  "Freshfields Deringer, Brussels (Associate 1997-1999)",
                  "BOCAR, S.A. de C.V. (Deputy Legal Manager 1999-2005)",
                  "Bristol Myers Squibb de Mexico, S de R.L de C.V. (Legal Director 2005-2008)",
                  "Bristol Myers Squibb Company, Princeton NJ (Deputy General Counsel Latin America, Intercontinental Region, 2008-2012)",
                  "Aggreko LLC, Houston, TX (Vice President of Commercial Risk and Head of Latin America Legal 2013-2017)",
                  "IHMA Abogados y Consultores, S.C. (2017 – 2022)", 
                  "BALL-IHMA (Junio 2023-presente)"
                ],
                socioMiembroDe: [
                  "Mexican Bar Association",
                  "Foreign Legal Consultant, certified by The Supreme Court of Texas",
                ],
                areasDePractica: [
                  "Corporate, Contracts, Antitrust, Public Tenders, International law, arbitration, Foreign Trade. Expertise in the automotive, pharmaceutical, nutritional and energy industries.",
                ],
                imagenCuadrada: `${DiegoMaxCuadrado}`,
                estatusSocio: "Partner",
                nacSocio: "Born in New York City. January 5, 1967.            ",
                titulacionSocio: "Admitted, 1992, Mexico. ",
                idiomaSocio: "Spanish, English, German and French",
                idiomaTitle: "Language:",
                emailSocio: " dandrade@ihma.law",
                vCard: "https://s3-us-west-1.amazonaws.com/ihma.law/VCards/Socios/DiegoAndradeMax.vcf"
              },
            ],
          socioSolicitado: "",
          AreasPractica: {
            id: "1",
            enfoque: "",
            title: "Practices Areas",
            subtitle:
              "We focus on high quality standards and a wide range of services",
            description:
              "Each one of the partners, associates and law clerks of the firm specializes in one or more of the following practices areas:",
            areas: [
              "Administrative Litigation ",
              "Insurance",
              "Antitrust",
              "Intellectual Property",
              "Automotive Industry",
              "Joint Ventures",
              "Background Check",
              "Labor Consulting ",
              "Compliance ESG",
              "Labor Litigation ",
              "Corporate",
              "Maritime",
              "Energy",
              "Mining",
              "Environmental",
              "Mergers & Acquisitions",
              "Equity Investments",
              "Oil & Gas",
              "Pharmaceutical Industry ",
              "FCPA - UKBA investigations",
              "Project Finance",
              "Film Industry",
              "Public Tenders ",
              "Finance",
              "Real Estate",
              "Financial Reestructuring",
              "Social Security",
              "Health",
              "Strategic Alliances",
              "Hotels & Hospitality",
              "Telecommunications",
              "Immigration",
              "Transportation & Aviation",
              "Infrastructure",
            ],
          },
          Publicaciones: {
            id: "1",
            enfoque: "Nuestro Enfoque:",
            title: "Our Locations",
            title2: " Other Locations",
            title3: "https://ballpllc.com/",
            subtitle:
              "Altos estándares de calidad y una amplia gama de servicios",
            description:
              "Cada uno de los socios, asociados y pasantes del Bufete se Especializan en una o más de las siguientes áreas de práctica:",
            areas1: [
              {
                name: "Houston, Texas USA",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Anuncio-Servicios-Compilance.pdf",
                dir: "1400 Broadfield Boulevard, Suite 200",
                ciudad: "Houston, TX 77084",
                tel: "+1.281.519.3335"
              },
              {
                name: "Dallas, Texas USA",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Anuncio-Servicios-Compilance.pdf",
                dir: "5445 La Sierra Drive, Suite 415",
                ciudad: "Dallas, Texas 75231",
                tel: "+1.281.519.3335"
              },
              {
                name: "Washington, D.C. USA",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "1717 Pennsylvania Avenue NW, 10th Floor",
                ciudad: "Washington, D.C. 20006",
                tel: "+1.202.559.9100"
              },
              {
                name: "Jakarta, Indonesia",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "The City Tower, Level 12-1N, Jalan MH Thamrin No 81, Dukuh Atas",
                ciudad: "Jakarta 10310, DKI, Indonesia",
           
              },
              {
                name: "Mexico City, Mexico",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "Bosque de Ciruelos 130-501, Col. Bosques de las Lomas",
                ciudad: "Mexico City, Mexico 11700",

              },
              {
                name: "Santa Cruz, Bolivia",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "Calle 21 de mayo No. 633 ",
                ciudad: "Santa Cruz, Bolivia",
             
              },
              {
                name: "Sao Paulo, Brazil",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "760, Princesa D’Oeste Avenue, Zip 13100-040",
                ciudad: "Campinas – São Paulo",
             
              },
              {
                name: "Buenos Aires, Argentina",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                dir: "Buenos Aires Av. Córdoba N° 3029, Piso 2, Oficina C. ",
                ciudad: "Buenos Aires, Argentina",
             
              },
              {
                name: "Mendoza, Argentina",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Boletin-Informativo-dic.pdf",
                dir: "Agustín Álvarez 589",
                ciudad: "Mendoza, Argentina",
              },
              {
                name: "Madrid, Spain",
                link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Boletin-Informativo-dic.pdf",
                dir: "Calle Jorge Juan, 65 2º, (65 Jorge Juan Street, Second Floor)",
                ciudad: "Madrid, Spain 28009",
              },
            ],
            areas2:[
              {
                
                  name: "Mexico City, Mexico",
                  link: "https://s3.us-west-1.amazonaws.com/ihma.law/boletines/esp/Reformas-Fiscales-2021-en-Mexico.pdf",
                  dir: "Bosque de Ciruelos 130-501, Col. Bosques de las Lomas",
                  ciudad: "Mexico City, Mexico 11700",
                  mail:"contacto@ihma.law",
                  tel: "+52.55.5292.6191",
  
                
                
              },
            ]
          },
          clientesData: {
            titulo: "Representative Clients",
            descripcion:
              "IHMA attorneys are recognized for their legal innovation and excellent client service",
            clientesList: [
              "1ero Dinero",
              "Aegerion Pharmaceuticals",
              "Aeromexico Cargo",
              "Aeroservicios Especializados",
              "Aggreko",
              "AIM",
              "Allrig",
              "Alto",
              "Amerex Brokers",
              "AMK Healthcare",
              "Arsenal Capital Partners",
              "AXA",
              "Beesion Technologies México",
              "Beyond Trust Software Inc.",
              "Bristol-Myers Squibb de México",
              "Cámara Mexicana de la Industria de la Construcción",
              "Capital Inmobiliario",
              "Columbia Laboratorios",
              "Combustibles Masal",
              "Corporativo Laudex",
              "Cree Ser México PF",
              "Dade Behring",
              "Daito Gyourui Co.",
              "Datacard",
              "Digital Entertainment",
              "Ding",
              "Echostar",
              "Elastomeros Taza",
              "Empacadora Sabadell",
              "Equipos Médicos Vizcarra",
              "Expo Pack México",
              "Gate Gourmet",
              "Georesearch",
              "GIFF",
              "Golden Gate Capital",
              "Groke",
              "Grupo Quiper",
              "Grupo Rendichicas",
              "GTA Telecom de México",
              "Guthy Renker",
              "Hanosil International",
              "HIG Capital Partners",
              "High Level Films",
              "Hoteles City Express",
              "Huatan",
              "HYT Capital",
              "Industria de la Construcción",
              "IRASA Fortec",
              "Jos A. Bank Clothiers",
              "Joy Global México",
              "Krispy Kreme Doughnut Corporation ",
              "Lean Solutions Group",
              "Lease Plan México",
              "Lek Securites Corporation",
              "Lemon Films",
              "Lemon Media",
              "Leo Pharmaceuticals",
              "Lexus",
              "Maasa México",
              "Marking Services",
              "McCormik México",
              "McCormik Pesa",
              "Meyn Beheer, B.V.",
              "Movilab",
              "MW Rental Power",
              "Nova Condesa",
              "Orca Mining",
              "Packaging Machinery",
              "Manufacturers Institute Inc.",
              // "Pandora Jewelry",
              "PC Capital",
              "Peco Pallet México",
              "Pitney Bowes",
              "Powermate Corp.",
              "Sagem Securité",
              // "Schneider México",
              "Siemens",
              "Stewart Connector Systems",
              "Sun Capital Ventures",
              // "Takeda",
              "Tamoin",
              "Tower One Wireless",
              "Toyota",
              "Tresmontes Lucchetti México",
              "Truelock",
              "United Parcel Service (UPS)",
              "WFI",
              "Winco",
              "About the partners",
            ],
          },
        },
      },
    };

  switchLanguage = () => {
    if (this.state.actual === "es") {
      return this.state.language.es;
    } else if (this.state.actual === "en") {
      return this.state.language.en;
    }
  };

  changeLanguage = (a) => {
    console.log("cambiador listo", a);
    let idioma;
    if (this.state.actual === "es") {
      idioma = "en";
    } else if (this.state.actual === "en") {
      idioma = "es";
    }
    return this.setState({
      ...this.state,
      actual: idioma,
    });
  };

  evento = (id) => {
    this.setState({
      language: {
        ...this.state.language,
        es: {
          ...this.state.language.es,
          socioSolicitado: `${id}`,
        },
        en: {
          ...this.state.language.en,
          socioSolicitado: `${id}`,
        },
      },
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Layout
          nav={this.switchLanguage().nav}
          changeLanguage={this.changeLanguage}
          language={this.switchLanguage()}
        >
          <Switch>
            <Route exact path="/"
              render={() => (
                <Home
                  language={this.switchLanguage()}
                />
              )
              }
            />
            <Route exact path="/Equipo-Legal"
              render={() =>
                <EquipoLegal
                  leftEquipoLegal={fotoEquipoLegal}
                  datosSocios={this.switchLanguage().dataSocios}
                  evento={this.evento}
                  language={this.switchLanguage()}
                />
              }
            />
            <Route exact path="/Equipo-Legal2"
              render={() =>
                <EquipoLegal2
                  rightEquipoLegal2={fotoEquipoLegal2}
                  datosSocios2={this.switchLanguage().dataSocios2}
                  evento={this.evento}
                  language={this.switchLanguage()}
                  elegido={this.switchLanguage().socioSolicitado}
                />
              }
            />
            <Route exact path="/Equipo-Legal/info"
              render={() =>

                <Socios
                  data={this.switchLanguage().dataSocios[this.switchLanguage().socioSolicitado - 1]}
                />
              }
            />
            <Route exact path="/Equipo-Legal2/info"
              render={() =>

                <Socios2
                  data={this.switchLanguage().dataSocios2[this.switchLanguage().socioSolicitado - 1]}
                />
              }
            />
            <Route exact path="/AreasDePractica" render={() =>
              <AreasPractica
                data={this.switchLanguage().AreasPractica}
              />
            }
            />
            <Route exact path="/Clientes" render={() =>
              <Clientes
                data={this.switchLanguage().clientesData}
              />

            }
            />
            <Route exact path="/alianzas" render={() => (
              <Alianzas
                language={this.switchLanguage()}
              />
            )}
            />
            <Route exact path="/reconocimientos" render={() => (
              <Reconocimientos
                // language={this.switchLanguage()}
                data={this.switchLanguage().reconocimientos}
              />
            )}
            />
            <Route exact path="/publicaciones" render={() => (
              <Publicaciones
                data={this.switchLanguage().Publicaciones}
              />
            )}
            />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
