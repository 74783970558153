import React from 'react';
import './styles/HeroHome.scss';
import textura from '../img/grisTextura.png';
import rojo from '../img/Imagen-home.png';
import logo from '../img/logoHero.png';

class HeroHome extends React.Component {
    render() {
        const { heroDescription1, heroDescription2 } = this.props;
        return (

            <div className="HeroHome2">
      

                {/* <img src={textura} alt="" className="HeroHome__textura" /> */}
                {/* <img src={rojo} alt="" className="HeroHome__color" /> */}
                {/* <img src={logo} alt="" className="HeroHome__logo" /> */}
                {/* <p className="HeroHome__description"> {heroDescription1}<br /> {heroDescription2} </p> */}
            
        <iframe
          width="100%"
          height="315"
          src="https://ballpllc.com/wp-content/uploads/2021/12/city-video-1920x1280-1.mp4?autoplay=1&loop=1"
          title="Video"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        />
        <div  className="text">
        <h1>Business</h1>
        <p>Problem Solvers</p>

        </div>
            
            
            
            </div>

        );
    }
}

export default HeroHome;




