import React from 'react';
import './styles/Publicaciones.scss';
import imagenDecoracion from '../img/areas-left.png';
import escuadra from '../img/escuadra.png';
class AreasPractica extends React.Component {
  render() {
    console.log(this.props.data.areas)

    return (
      <div className="AreasPractica">
        <div className="AreasPractica__decoration">
          <img src={imagenDecoracion} alt="" className="AreasPractica__decoration-img" />
        </div>
        <div className="AreasPractica__contenido" >
          <h1 className="AreasPractica__contenido-title">
            <img src={escuadra} alt="" />
            {this.props.data.title}
          
          </h1>
          {/* <h4 className="AreasPractica__contenido-subtitle"> {this.props.data.enfoque} <br /> {this.props.data.subtitle} </h4>
          <p className="AreasPractica__contenido-description"> {this.props.data.description} </p> */}
          <div className="Publi__contenido-list container1">
            {this.props.data.areas2.map((areas) => {
              return (
                <div key={areas.name} >
                  <div>
                  </div>
                  <a >
                    <p> {areas.name} </p>
                    <p1 class="color2"> {areas.dir} </p1>
                    <p1 class="color2"> {areas.ciudad} </p1>
                    <br></br>
                    <p1 class="color1"> {areas.mail} </p1>
                    <br></br>

                    <p1 class="color1"> {areas.tel} </p1>
                    <br></br>
                  </a>
                </div>
              )
            })}
          </div>
          <br></br>
          <h1 className="AreasPractica__contenido-title">
            {/* <img src={escuadra} alt="" /> */}
            {this.props.data.title2}
      

            <h4 className='h4t'>https://ballpllc.com/</h4>
          
          </h1>

          <div className="Publi__contenido-list container1">
            {this.props.data.areas1.map((areas) => {
              return (
                <div key={areas.name} >
                  <div>
                  </div>
                  <a >
                    <p> {areas.name} </p>
                    <p1 class="color2"> {areas.dir} </p1>
                    <p1 class="color2"> {areas.ciudad} </p1>
                    <br></br>
          

                    {/* <p1 class="color1"> {areas.tel} </p1>
                    <br></br> */}
                  </a>
                </div>
                

              )
            })}
          </div>





        </div>
      </div>
    );
  }
}

export default AreasPractica;